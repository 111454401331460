<template>
  <div class="page_header">
    <div v-if="state == 'true'" @click="goBack()" class="icon">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="content">{{ msg }}</div>
  </div>
  <div style="height: 4rem;width: 100%;">
	  
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    msg: String,
    state: String,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  // setup(props) {
  //     console.log(props.state)
  // },
};
</script>

<style>
.page_header {
  background-color: #fff;
  line-height: 4rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.icon {
  position: absolute;
  font-size: 2.5rem;
  left: 1%;
}
.content {
  text-align: center;
  font-size: 1.8rem;
}
</style>
