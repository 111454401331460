<template>
  <PageHeader state="false" msg="支付成功"></PageHeader>
  <div class="container_boxs">
    <div class="top clearfix">
      <div class="logo fl"><img src="../../assets/img/1.jpg" /></div>
      <div class="name_box fl">
        <div class="name">{{ name }}</div>
        <div>充电桩</div>
      </div>
      <div class="fr" style="text-align: center">
        <div style="margin-bottom: 1rem">{{ port }}号</div>
        <div>充电桩</div>
      </div>
    </div>
    <div class="info">
      <div class="item clearfix">
        <div class="left fl">套餐：</div>
        <div class="right fr">{{ packageName }}</div>
      </div>
      <div class="item clearfix">
        <div class="left fl">支付方式：</div>
        <div v-show="pay == 1" class="right fr">微信支付</div>
        <div v-show="pay == 2" class="right fr">支付宝支付</div>
      </div>
      <div class="item clearfix">
        <div class="left fl">支付金额：</div>
        <div class="right fr">￥{{ amount }}</div>
      </div>
      <div class="item clearfix">
        <div class="left fl">开始时间：</div>
        <div class="right fr">{{ startTime }}</div>
      </div>
      <div class="item clearfix">
        <div class="left fl">结束时间：</div>
        <div class="right fr">{{ endTime }}</div>
      </div>
    </div>
  </div>
  <div class="hint">
    充电中...
    <br />
    充电期间不妨进入优选商城逛逛吧~
  </div>
  <div class="btn_box">
    <div @click="goHome()" class="item fl btn1">首页</div>
    <div @click="goShop()" class="item fl btn2">逛逛优选商城</div>
  </div>
</template>

<script>
export default {
	data() {
		return {
			port: "",
			name: "",
			pay: "",
			amount: "",
			startTime: "",
			endTime: "",
			packageName: "",
		};
	},
	mounted() {
		localStorage.removeItem("uniqueCode");
		this.port = this.$route.params.port; //充电端口号
		this.name = this.$route.params.name; //充电桩名称
		this.pay = this.$route.params.apy; //充电桩名称
		this.amount = this.$route.params.amount; //金额
		this.startTime = this.$route.params.startTime; //开始时间
		this.endTime = this.$route.params.endTime; //结束时间
		this.packageName = this.$route.params.packageName; //套餐名称
	},
	methods: {
		goHome() {
			this.$router.push("home");
		},
		goShop() {
			this.$router.push("shopHome");
		},
	},
};
</script>

<style lang="scss" scoped>
.top {
  font-size: 1.5rem;
  .logo {
    width: 10rem;
    height: 5.5rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .name_box {
    width: 8rem;
    padding: 0 1rem;
    text-align: center;
    font-weight: 600;

    .name {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 1rem;
    }
  }
}
.info {
  font-size: 1.4rem;
  padding: 1.25rem 0 1rem;
  .item {
    line-height: 2.5rem;
    .left {
      font-weight: 600;
    }
    .right {
    }
  }
}
.hint {
  margin-top: 5rem;
  text-align: center;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 5rem;
}
.btn_box {
  position: fixed;
  bottom: 3rem;
  width: 94%;
  left: 3%;
  text-align: center;
  .item {
    background-color: #f9d855;
    color: #fff;
    border-radius: 0.625rem;
    line-height: 4rem;
  }
  .btn1 {
    width: 20%;
    margin-right: 5%;
    background-color: #426aa4;
  }
  .btn2 {
    width: 75%;
  }
}
</style>
