<template>
	<pageHeader :msg='shopInfo.shopName' state='true'></pageHeader>
	<!-- 店铺信息 -->
	<div class="shop_info">
		<div class="clearfix">
			<div class="shop_info_img fl">
				<el-image style="width: 8rem;height: 6rem;" :src="shopInfo.logoImg" fit="cover" ></el-image>
				<!-- <img :src="shopInfo.logoImg" > -->
			</div>
			<div class="fl shop_info_txt">
				<div class="shop_info_name">{{shopInfo.shopName}}</div>
				<div class="shop_info_address">{{shopInfo.address}}</div>
			</div>
		</div>
		<div class="shop_info_list">
			<div>联系电话：{{shopInfo.contactMobile}}</div>
		</div>
	</div>
	
	<!-- 搜索框 -->
	<!-- <div class="search_box">
		<input type="search" name="" id="" value="" placeholder="请输入名称"/>
	</div> -->
	<form action="" @submit.prevent="goodsSearchList">
		<div class="search_box">
			<input type="search" placeholder="请输入名称" v-model="searchText"/>
		</div>
	</form>
	
	
	<!-- 商品列表 -->
	<div class="shop_box clearfix">
		<div class="fl shop_classify">
			<div v-for="item in category" :key="item" @click="classChange(item.id)" class="shop_classify_item" :class="{shop_classify_active:classId == item.id}">{{item.category}}</div>
		</div>
		<div class="fl shop_list clearfix">
			<el-empty v-show="shopList.length == 0" description="暂无商品" :image-size="150"></el-empty>
			<ul v-show="shopList.length != 0" infinite-scroll-disabled="disabled" class="infinite-list" v-infinite-scroll="getGoodsList" style="overflow: auto; height: 100%" infinite-scroll-immediate="false">
				<div class="shop_item fl" @click="goOrder(item.id)" v-for="item in shopList" :key="item">
					<div class="shop_item_img"><img :src="item.goodsCover" ></div>
					<div class="shop_item_name">{{item.goodsTitle}}</div>
					<div class="clearfix">
						<div class="fl shop_item_sale">销量：{{item.totalSales}}</div>
						<div class="fr shop_item_price">￥{{item.sellPrice}}</div>
					</div>
				</div>
			</ul>
		</div>
	</div>
</template>

<script>
	import { userShopGoodsList,userCategoryGoodsList,goodsSearchList } from "../../http/index.js";
	export default{
		data(){
			return {
				classId:1,
				state: false, //是否继续加载列表 false为继续  true为停止
				shopList:[],
				category:[],
				shopInfo:'',
				searchText:''
			}
		},
		methods:{
			classChange(num){
				this.classId = num
				this.getGoodsList()
			},
			goOrder(id){
				this.$router.push({name:'ShopOrder',params:{id:id}})
			},
			getShopInfo(){
				let data = {
					shopId:this.shopId
				}
				userShopGoodsList(data).then((res)=>{
					this.shopInfo = res.data.shopInfo
					// console.log(res)
					if(res.data.shopCategories.length > 0){
						this.category = res.data.shopCategories
						this.classId = res.data.shopCategories[0]['id']
						this.getGoodsList()
					}
				})
			},
			getGoodsList(){
				let data = {
					categoryId:this.classId
				}
				userCategoryGoodsList(data).then((res)=>{
					// console.log(res.data)
					this.shopList = res.data
				})
			},
			goodsSearchList(){
				let data = {
					shopId:this.shopId,
					searchText:this.searchText,
					categoryId:'',
					pageNo:1, 
					pageCount:1000
				}
				goodsSearchList(data).then((res)=>{
					// console.log(res)
					this.shopList = res.data
				})
			}
		},
		computed: {
			disabled() {
				return this.state;
			},
		},
		mounted() {
			this.shopId = this.$route.params.id
			this.getShopInfo()
		},
		activated() {
			if(!this.$route.meta.isBack && this.isFirstEnter){
				this.shopId = this.$route.params.id
				this.classId = 1,
				this.state =  false, //是否继续加载列表 false为继续  true为停止
				this.shopList = [],
				this.category = [],
				this.shopInfo = ''
				this.getShopInfo()
			}
			this.$route.meta.isBack=false
			this.isFirstEnter=true;
		},
		created() {
			this.isFirstEnter=false;
		}
	}
</script>

<style scoped lang="scss">
	.shop_info{
		width: calc(92% - 2rem);
		padding: 1rem;
		margin: 1rem auto;
		background-color: #fff;
		.shop_info_img{
			width: 8rem;
			height: 6rem;
			border-radius: 1rem;
			overflow: hidden;
			img{
				width: 8rem;
				height: 6rem;
			}
		}
		.shop_info_txt{
			width: calc(100% - 9rem);
			margin-left: 1rem;
			.shop_info_name{
				font-weight: 600;
				font-size: 1.6rem;
			}
			.shop_info_address{
				color: #666;
				text-overflow: ellipsis;
				display:-webkit-box;
				-webkit-line-clamp:2;
				-webkit-box-orient:vertical;
				overflow: hidden;
				
			}
		}
		.shop_info_list{
			font-size: 1.4rem;
			color: #aaa;
			line-height: 2.5rem;
			border-top: 0.0625rem solid #ccc;
			margin-top: 1rem;
		}
	}
	.search_box{
		background-color: #fff;
		width: 92%;
		margin: 1rem auto;
		padding: 1rem 0;
		font-size: 1.5rem;
		height: 2.5rem;
		line-height: 2.5rem;
		input{
			width: 90%;
			padding: 0 5%;
			height: 2.5rem;
		}
	}
	.shop_box{
		background-color: #fff;
		width: 92%;
		height: calc(100vh - 30rem);
		margin: 1rem auto;
		border-radius: 1rem;
		font-size: 1.4rem;
		padding: 1rem 0;
		.shop_classify{
			width: 8rem;
			text-align: center;
			.shop_classify_item{
				border-right: 0.0625rem solid #ccc;
				padding: 1rem 0;
				border-bottom: 0.0625rem solid #ccc;
			}
			.shop_classify_item:last-child{
				border-bottom: none;
			}
			.shop_classify_active{
				background-color: #f9d855;
				color: #fff;
			}
		}
		.shop_list{
			width: calc(100% - 8rem);
			height: 100%;
			.shop_item{
				width: calc(50% - 1rem);
				margin: 0 0.5rem 1rem;
				box-shadow: 0.125rem 0.125rem 0.375rem #ccc;
				border-radius: 0.8rem;
				overflow: hidden;
				padding-bottom: 1rem;
				.shop_item_img{
					width: 100%;
					height: 8rem;
					img{
						width: 100%;
						height: 8rem;
					}
				}
				.shop_item_name{
					text-overflow: ellipsis;
					display:-webkit-box;
					-webkit-line-clamp:2;
					-webkit-box-orient:vertical;
					overflow: hidden;
					font-size: 1.3rem;
					margin: 0.5rem;
				}
				.shop_item_sale{
					margin-left: 0.5rem;
					font-size: 1.2rem;
					color: #aaa;
					line-height: 2.5rem;
				}
				.shop_item_price{
					color: red;
					font-size: 1.4rem;
					margin-right: 0.5rem;
					line-height: 2.5rem;
				}
				
			}
		}
	}
</style>
