<template>
  <el-backtop>
    <div
      style="
         {
          height: 100%;
          width: 100%;
          background-color: #f2f5f6;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
          text-align: center;
          line-height: 4rem;
          color: #1989fa;
        }
      "
    >
      Top
    </div>
  </el-backtop>
	<PageHeader state="true" msg="充电记录"></PageHeader>
	<div v-if="list.length > 0">
		<div class="record_list">
			<div class="record_item" v-for="(item,index) in list" :key="item">
				<div v-if="item.status == 0" style="color: red" class="item_title">未支付</div>
				<div v-if="item.status == 1" style="color: #32cd32" class="item_title">已支付
					<div v-if="index == 0" @click="refund(item.orderId)" class="btns">退款</div>
				</div>
				<div class="clearfix item_info">
					<div class="fl">充电桩名称：</div>
					<div class="fr">{{ item.chargerAddress }}</div>
				</div>
				<div class="clearfix item_info">
					<div class="fl">套餐：</div>
					<div class="fr">{{ item.chargerCharging }}</div>
				</div>
				<div class="clearfix item_info">
					<div class="fl">支付方式：</div>
					<div class="fr">{{ item.payWay }}</div>
				</div>
				<div class="clearfix item_info">
					<div class="fl">开始时间：</div>
					<div class="fr">{{ item.startTime }}</div>
				</div>
				<div class="clearfix item_info">
					<div class="fl">订单id：</div>
					<div class="fr">{{ item.orderId }}</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else>
		<el-empty description="您还没有充电记录哦!" :image-size="200"></el-empty>
	</div>
	<div v-if="state == 2" style="display: flex;margin: 1rem 0;justify-content: center;align-items: center;">
		没有数据咯~~~
	</div>
</template>

<script>
import { userChargerOrder,chargerOrderRefund } from "../../http/index.js";
import { ElMessage } from "element-plus";
export default {
	data() {
		return {
			list: [],
			num: 1,
			state: 1,
		};
	},
	mounted() {
		this.getUserChargerOrder(this.num);
		window.addEventListener("scroll", this.handleScroll, true);
	},
	methods: {
		getUserChargerOrder(num) {
			let userInfo = JSON.parse(localStorage.getItem("userInfo"));
			let u = userInfo["userId"];
			let data = {
				userId: u,
				pageNo: num,
				pageCount: 5,
			};
			userChargerOrder(data).then((res) => {
				if (res.data.length == 0) {
					this.state = 2;
				}
				this.list = this.list.concat(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
		},
		handleScroll() {
			let scrollTop =
				document.body.scrollTop || document.documentElement.scrollTop;
			var h = document.documentElement.clientHeight || document.body.clientHeight;
			if (scrollTop + h >= 1000 * (this.num - 1)) {
				this.num = this.num + 1;
				this.getUserChargerOrder(this.num);
			}
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 2000,
				showClose: true,
			});
		},
		refund(orderId){
			let data = {
				orderId: orderId
			}
			chargerOrderRefund(data).then((res)=>{
				console.log(res)
				// if(res)
				if(res.code == 200){
					this.succes('退款成功')
				}else{
					this.warnings(res.message)
				}
				
			})
		}
	},
};
</script>

<style lang="scss">
.record_list {
	margin-bottom: 6.25rem;
}
.record_item {
	width: 88%;
	padding: 1.25rem 3%;
	margin-left: 3%;
	background-color: #fff;
	margin-top: 1.2rem;
	border-radius: 1.875rem;
	box-shadow: 0.2rem 0.375rem 0.375rem #999;
	.item_title {
		font-size: 1.75rem;
		font-weight: 600;
		border-bottom: 0.0625rem solid #000;
		position: relative;
		.btns{
			position: absolute;
			right: 0;
			top: 0;
			
		}
	}
	.item_info {
		line-height: 2.5rem;
	}
}
</style>
