<template>
	<PageHeader state="true" msg="我要加盟"></PageHeader>
	<div class="join_box">
		<div class="item">
			<div class="title"><span>*</span>联系人姓名</div>
			<div class="content">
				<input v-model="name" placeholder="请输入联系人姓名"/>
			</div>
		</div>
		<div class="item">
			<div class="title"><span>*</span>联系人电话</div>
			<div class="content">
				<input v-model="phone" type="tel" placeholder="请输入联系人电话"/>
			</div>
		</div>
		<div class="item">
			<div class="title"><span>*</span>描述</div>
			<div class="" style="margin-top: 0.9375rem">
				<el-input type="textarea" placeholder="请输入描述信息" v-model="describe" maxlength="50" show-word-limit rows="4"></el-input>
			</div>
		</div>
		<div class="save" @click="save()">提交</div>
	</div>
</template>

<script>
import { addHandle } from "../../http/index.js";
import { ElMessage } from "element-plus";
import { ref } from "vue";
export default {
	data() {
		return {
			name: "",
			phone: "",
		};
	},
	setup() {
		return {
			describe: ref(""),
		};
	},
	methods: {
		save() {
			let that = this;
			if (that.name == "") {
				this.warnings("请输入您的姓名");
				return false;
			}
			if (that.phone == "") {
				this.warnings("请输入您的联系方式");
				return false;
			}
			if (that.describe == "") {
				this.warnings("请输入您的描述内容");
				return false;
			}
			let data = {
				realName: that.name,
				tel: that.phone,
				content: that.describe,
			};
			addHandle(data).then((res) => {
				if (res.code == -1) {
					this.warnings(res.message);
				} else {
					this.succes("您的加盟信息已提交，请等待工作人员联系");
					setTimeout(function () {
						that.$router.back();
					}, 2000);
				}
			})
			.catch((error) => {
			console.log(error);
			});
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 2000,
				showClose: true,
			});
		},
	},
};
</script>

<style scoped lang="scss">
textarea {
	color: red !important;
}
.join_box {
	width: 85%;
	margin: 0 auto;
	font-size: 1.5rem;
	.item {
		margin-top: 2rem;
	}
	span {
		color: red;
		margin-right: 0.625rem;
	}
	.content {
		margin-top: 0.9375rem;
		background-color: #fff;
		height: 3.125rem;
		border: 0.0625rem solid #ccc;
		input {
			width: 90%;
			padding: 0 5%;
			height: 100%;
		}
	}
	.save {
		position: fixed;
		bottom: 3rem;
		text-align: center;
		width: 85%;
		background-color: #f9d855;
		color: #fff;
		border-radius: 1.5rem;
		line-height: 4rem;
	}
}
</style>
