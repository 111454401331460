<template>
	<pageHeader msg='优选订单' state='true'></pageHeader>
	
	<div class="containers">
		<!-- 搜索框 -->
		<!-- <div class="search_box">
			<input type="" name="" id="" value="" placeholder="请输入搜索内容"/>
		</div> -->
		<form action="" @submit.prevent="getList()">
			<div class="search_box">
				<input type="search" v-model="searchText" placeholder="请输入搜索内容"/>
			</div>
		</form>
		
		<!-- 导航栏 -->
		<div class="clearfix nav_box">
			<div @click="navChange(1)" class="fl nav_item" :class="{nav_active : navState == 1}">全部</div>
			<div @click="navChange(2)" class="fl nav_item" :class="{nav_active : navState == 2}">待支付</div>
			<div @click="navChange(3)" class="fl nav_item" :class="{nav_active : navState == 3}">待核销</div>
			<div @click="navChange(4)" class="fl nav_item" :class="{nav_active : navState == 4}">已完成</div>
			<!-- <div @click="navChange(4)" class="fl nav_item" :class="{nav_active : navState == 4}">审核</div> -->
		</div>
		<!-- 排序 -->
<!-- 		<div class="sort_box clearfix">
			<div @click="sortChange(1)" :class="{sort_active : sortState == 1}" class="sort_item fl">价格
				<img v-show="sortState == 2" src="../../assets/icon/bottom.png" >
				<img v-show="sortState == 1" src="../../assets/icon/bottom_hl.png" >
			</div>
			<div @click="sortChange(2)" :class="{sort_active : sortState == 2}" class="sort_item fl">销量
				<img v-show="sortState == 1" src="../../assets/icon/bottom.png" >
				<img v-show="sortState == 2" src="../../assets/icon/bottom_hl.png" >
			</div>
		</div> -->
		
		<el-empty v-show="list.length == 0" description="暂无相关订单" :image-size="150"></el-empty>
		<!-- 商品列表 -->
		<div v-show="list.length != 0" class="list_box">
			<ul infinite-scroll-disabled="disabled" class="infinite-list" v-infinite-scroll="getList" style="overflow: auto; height: 100%" infinite-scroll-immediate="false">
				<div class="item" @click="goEdit" v-for="(item,index) in list" :key="item">
					<div class="clearfix item_state">
						<div class="fl">订单号：{{item.id}}</div>
						<div class="fr" v-show="item.status == 1">待支付</div>
						<div class="fr" v-show="item.status == 2">待核销</div>
						<div class="fr" v-show="item.status == 5">已完成</div>
					</div>
					<div class="clearfix">
						<div class="item_img fl"><img :src="item.goodsCover" ></div>
						<div class="fl item_info">
							<div class="item_title">{{item.goodsTitle}}</div>
							
							<div class="item_content clearfix">
								<div class="fl">单价：{{item.sellPrice}}</div>
								<div class="fr">数量：{{item.goodsCount}}</div>
							</div>
							<div style="text-align: right;">总计：<span class="item_price">{{item.amount}}元</span></div>
						</div>
					</div>
					<div class="item_hidden">
						<div>下单时间：{{item.payTime}}</div>
						<div>下单人：{{item.userName}}</div>
						<div class="clearfix">
							<div class="item_btn" v-show="item.status == 1" @click="goPay(item.orderNo)">去支付</div>
							<div class="item_btn" v-show="item.status == 2" @click="goRefund(item.orderNo,item.amount)">退款</div>
							<div class="item_btn" v-show="item.status == 3">已退款</div>
							<div class="item_btn" v-show="item.status == 4">退款失败</div>
							<div class="item_btn" v-show="item.status == 5">已完成</div>
						</div>
					</div>
					<div class="item_extend">
						<img class="extend_img" @click="open(index)" src="../../assets/icon/xia.png" >
					</div>
				</div>
			</ul>
		</div>
	</div>
	
	
	
	
	
</template>

<script>
	import shang from '../../assets/icon/shang.png'
	import xia from '../../assets/icon/xia.png'
	
	import { userOrderList,shopWeChatPay,shopAliPayWap,orderReverse } from "../../http/index.js";
	import { ElMessage } from "element-plus";
	export default{
		data(){
			return{
				navState:1,
				clickId:-1,
				list:[],
				status:'',
				searchText:'',
				searchText2:'',
				state:false,
				pageNo:1,
				payState:1
			}
		},
		computed: {
			disabled() {
				return this.state;
			},
		},
		methods:{
			readys(callback) {
				// 如果jsbridge已经注入则直接调用
				if (window.AlipayJSBridge) {
					callback && callback();
				} else {
					// 如果没有注入则监听注入的事件
					document.addEventListener("AlipayJSBridgeReady", callback, false);
				}
			},
			jsApiCall() {
				let that = this;
				let wxcon = that.wxcontent;
				// eslint-disable-next-line no-undef
				WeixinJSBridge.invoke("getBrandWCPayRequest", JSON.parse(wxcon), function (res) {
					// eslint-disable-next-line no-undef
					WeixinJSBridge.log(res.err_msg);
					// eslint-disable-next-line no-undef
					WeixinJSBridge.log(res);
					if (res.err_msg == "get_brand_wcpay_request:ok") {
						that.$router.push("orderSuccess");
					}
				});
			},
			
			callpay(val) {
				let jsApiParameters = JSON.parse(val);
				if (jsApiParameters == "undefined") {
					alert("参数错误！");
					return false;
				}
				if (typeof WeixinJSBridge == "undefined") {
					if (document.addEventListener) {
						document.addEventListener( "WeixinJSBridgeReady", this.jsApiCall(), false);
					} else if (document.attachEvent) {
						document.attachEvent("WeixinJSBridgeReady", this.jsApiCall());
						document.attachEvent("onWeixinJSBridgeReady", this.jsApiCall());
					}
				} else {
					this.jsApiCall();
				}
			},
			warnings(mess) {
				ElMessage.warning({
					message: mess,
					type: "warning",
					center: true,
					customClass: "warnClass",
					duration: 1500,
					showClose: true,
				});
			},
			succes(mess) {
				ElMessage.success({
					message: mess,
					type: "success",
					center: true,
					customClass: "warnClass",
					duration: 2000,
					showClose: true,
				});
			},
			navChange(num){
				this.navState = num
				this.list = []
				this.pageNo = 1
				if(num==1){
					this.status = ''
				}else if(num == 2){
					this.status = 1
				}else if(num ==3){
					this.status = 2
				}else if(num ==4){
					this.status = 5
				}
				this.getList()
			},
			open(id){
				this.close()
				if(id == this.clickId){
					this.clickId = -1
					return false;
				}
				this.clickId = id
				var arr = document.getElementsByClassName('item_hidden')
				arr[id].style.display = 'block'
				
				var arr2 = document.getElementsByClassName('extend_img')
				arr2[id].src = shang
			},
			close(){
				var arr = document.getElementsByClassName('item_hidden')
				for(var i=0;i<arr.length;i++){
					arr[i].style.display = 'none'
				}
				var arr2 = document.getElementsByClassName('extend_img')
				for(var w=0;w<arr2.length;w++){
					arr2[w].src = xia
				}
			},
			getList(){
				if(this.searchText != '' && this.searchText2 == '' || this.searchText != this.searchText2){
					this.status = ''
					this.navState = 1
					this.list = []
					this.pageNo = 1
					this.searchText2 = this.searchText
				}
				let data = {
					userId:localStorage.getItem('userId'),
					pageNo:this.pageNo,
					pageCount:10,
					status:this.status,
					searchText:this.searchText
				}
				userOrderList(data).then((res)=>{
					if(res.data.length == 0){
						this.state = true
					}else{
						this.pageNo = this.pageNo+1
						this.list = this.list.concat(res.data)
					}
				})
			},
			goPay(orderNo){
				let that = this
				let datas = {
					userId:localStorage.getItem('userId'),
					orderNo:orderNo
				}
				if (that.payState == 1) {
					shopWeChatPay(datas).then((res) => {
						if (res.code == 400 || res.code == -1) {
							that.warnings(res.message);
						} else if (res.code == 200) {
							that.wxcontent = res.data.expend["pay_info"];
							that.callpay(res.data.expend["pay_info"]);
						}
					})
				    .catch((err) => {
						console.log(err);
				    });
				} else if (that.payState == 2) {
					shopAliPayWap(datas).then((res) => {
						this.readys(function () {window.AlipayJSBridge.call( "tradePay",
							{
								tradeNO: res.data.expend["pay_info"].split('"')[3],
							},
							function (result) {
								if (result["resultCode"] === "9000") {
									that.$router.push({name:'ShopSuccess',params:{
										num:that.num,
										amount:that.total,
										cover:that.arr['goodsCover'],
										name:that.arr['goodsName'],
										price:that.arr['price']
									}})
								} else {
									that.warnings(result["memo"]);
								}
							});
						});
				    })
					.catch((err) => {
						console.log(err);
					});
				}
			},
			goRefund(orderNo,amount){
				let data = {
					userId:localStorage.getItem('userId'),
					orderNo:orderNo,
					amount:amount
				}
				orderReverse(data).then((res)=>{
					if(res.code == 200){
						this.succes('退款成功')
						this.getList()
					}else{
						this.warnings(res.message)
					}
				})
			}
		},
		mounted() {
			if (/MicroMessenger/.test(window.navigator.userAgent)) {
				this.payState = 1;//微信
			} else if (/AlipayClient/.test(window.navigator.userAgent)) {
				this.payState = 2;//支付宝
			}
			this.getList()
		}
	}
</script>

<style scoped lang="scss">
	.containers{
		background-color: #F5F5F5;
		width: 100%;
		height: calc(100vh - 5rem);
		padding-top: 1rem;
		.search_box{
			width: 90%;
			background-color: #fff;
			line-height: 4rem;
			margin: 0 auto;
			border-radius: 100rem;
			input{
				padding-left: 5%;
				width: 95%;
				font-size: 1.5rem;
				height: 4rem;
			}
		}
		
		.nav_box{
			font-size: 1.6rem;
			background-color: #fff;
			text-align: center;
			color: #aaa;
			padding: 1rem 0;
			margin-top: 1.3rem;
			border-top: 0.0625rem solid #ccc;
			border-bottom: 0.0625rem solid #ccc;
			.nav_item{
				width: 25%;
			}
			.nav_active{
				color: #f9d855;
				font-weight: 600;
			}
		}
		
		.sort_box{
			width: 100%;
			background-color: #fff;
			line-height: 3rem;
			border-bottom: 0.0625rem solid #ccc;
			height: 3rem;
			color: #aaa;
			.sort_item{
				width: 33.33%;
				text-align: center;
				font-size: 1.3rem;
				img{
					width: 2rem;
					height: 2rem;
					position: relative;
					top: 0.5rem;
				}
			}
			.sort_active{
				color: #f9d855;
			}
		}
		
		.list_box{
			height: calc(100vh - 20rem);
			padding-top: 1rem;
			background-color: #fff;
			.item{
				width: 84%;
				padding: 1rem 4%;
				margin: 0 auto 1rem;
				border-radius: 1rem;
				box-shadow: 2px 2px 6px #ccc;
				.item_btn{
					background-color: #f9d855;
					color: #fff;
					width:10rem;
					text-align: center;
					font-size: 1.6rem;
					float: right;
					line-height: 3.5rem;
					border-radius: 1rem;
				}
				.item_state{
					font-size: 1.3rem;
					margin-bottom: 0.5rem;
				}
				.item_img{
					width: 8rem;
					height: 8rem;
					img{
						width: 100%;
						height: 100%;
					}
				}
				.item_info{
					width: calc(100% - 9rem);
					margin-left: 1rem;
					line-height: 2rem;
					.item_title{
						font-size: 1.5rem;
						font-weight: 700;
						text-overflow: ellipsis;
						display:-webkit-box;
						-webkit-line-clamp:2;
						-webkit-box-orient:vertical;
						overflow: hidden;
					}
					.item_price{
						color: #ff0000;
					}
					.item_content{
						font-size: 1.3rem;
						color: #aaa;
					}
				}
				.item_extend{
					text-align: center;
					padding: 0.5rem 0 0;
					img{
						width: 2.5rem;
						height: 2.5rem;
					}
				}
				.item_hidden{
					font-size: 1.3rem;
					margin-top: 0.8rem;
					line-height: 2.2rem;
					display:none;
				}
			}
		}
	}
	
</style>
