<template>
  <PageHeader state="true" msg="优惠券"></PageHeader>
  11111
</template>

<script>
import PageHeader from "../../components/PageHeader.vue";
export default {
  methods: {
    goBack() {
      console.log("go back");
      this.$router.go(-1);
    },
  },
  components: {
    PageHeader,
  },
};
</script>

<style></style>
