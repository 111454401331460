<template>
	<PageHeader state="true" msg="联系客服"></PageHeader>
	<div class="call_box">
		<a :href="'tel:' + '0572-6609197'">0572-6609197拨打</a>
	</div>
</template>

<script></script>

<style scoped lang="scss">
.call_box {
	width: 90%;
	height: 5rem;
	background-color: #f9d855;
	color: #fff;
	text-align: center;
	line-height: 5rem;
	position: fixed;
	bottom: 5%;
	left: 5%;
	border-radius: 1.25rem;
	font-size: 2rem;
	a {
		color: #fff;
		text-decoration: none;
	}
}
</style>
