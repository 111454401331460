import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/login.vue"; //登录页
import Home from "../views/home/home.vue"; //首页
import Me from "../views/me/me.vue"; //我的首页
import About from "../views/me/about.vue"; //关于我们
import RechargeRecord from "../views/me/recharge_record.vue"; //充电记录
import Coupons from "../views/me/coupons.vue";
import Feedback from "../views/me/feedback.vue"; //意见反馈
import Service from "../views/me/service.vue"; //联系客服
import ScanHome from "../views/scan/home.vue"; //扫码首页
import ChargePile from "../views/scan/chargepile.vue"; //充电桩
import OrderAffirm from "../views/scan/orderaffirm.vue"; //订单确认
import OrderSuccess from "../views/scan/ordersuccess.vue"; //支付成功
import ElectromobileHome from "../views/electromobile/home.vue"; //电动车服务首页
import JoinIn from "../views/home/join.vue"; //加盟
import Aliscan from "../views/scan/aliscan.vue"; //阿里端扫码界面
import Often from "../views/me/often.vue"; //常用充电桩记录
import ShopHome from "../views/shop/home.vue"; //商城首页
import ShopDetail from "../views/shop/detail.vue"; //店铺详情
import ShopOrder from "../views/shop/order.vue"; //商品详情
import ShopPay from "../views/shop/pay.vue"; //订单确认
import ShopSuccess from "../views/shop/success.vue"; //支付成功
import ShopRecord from "../views/shop/record.vue"; //订单记录

const routes = [
	{
		path: "/shopRecord",
		name: "ShopRecord",
		component: ShopRecord,
		meta: {
			footShow: false,
		},
	},
	{
		path: "/shopSuccess",
		name: "ShopSuccess",
		component: ShopSuccess,
		meta: {
			footShow: false,
		},
	},
	{
		path: "/shopPay",
		name: "ShopPay",
		component: ShopPay,
		meta: {
			footShow: false,
		},
	},
	{
		path: "/shopOrder",
		name: "ShopOrder",
		component: ShopOrder,
		meta: {
			footShow: false,
			keepAlive: true
		},
	},
	{
		path: "/shopDetail",
		name: "ShopDetail",
		component: ShopDetail,
		meta: {
			footShow: false,
			keepAlive: true
		},
	},
	{
		path: "/",
		name: "Login",
		component: Login,
	},
	{
		path: "/home",
		name: "Home",
		component: Home,
		meta: {
			footShow: true,
		},
	},
  {
    path: "/me",
    name: "Me",
    component: Me,
    meta: {
      footShow: true,
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/recharge_record",
    name: "RechargeRecord",
    component: RechargeRecord,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/coupons",
    name: "Coupons",
    component: Coupons,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/service",
    name: "Service",
    component: Service,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/scanhome",
    name: "ScanHome",
    component: ScanHome,
    meta: {
      footShow: true,
    },
  },
  {
    path: "/chargepile",
    name: "ChargePile",
    component: ChargePile,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/orderaffirm",
    name: "OrderAffirm",
    component: OrderAffirm,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/ordersuccess",
    name: "OrderSuccess",
    component: OrderSuccess,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/electromobilehome",
    name: "ElectromobileHome",
    component: ElectromobileHome,
    meta: {
      footShow: true,
    },
  },
  {
    path: "/joinin",
    name: "JoinIn",
    component: JoinIn,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/aliscan",
    name: "Aliscan",
    component: Aliscan,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/often",
    name: "Often",
    component: Often,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/shophome",
    name: "ShopHome",
    component: ShopHome,
    meta: {
      footShow: true,
	  keepAlive: true
    },
  },
];

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
});

router.afterEach((to) => {  // 一定要再afterEach中判断而不是beforeEach，因为beforeEach在点击返回之后获取到的值不准确，每返回一次，会获取到延后一次的to、history
  if (window.history.state && window.history.state.forward) { // 或者判断 to.forward,window.history.state.forward是vue-router写入的，当返回或前进的时候才会有值
	to.meta.isBack = true;
  } else {
    to.meta.isBack = false;
  }
});

export default router;
