import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
const app = createApp(App);

//引入基本样式
import "./common/css/base.css";
//引入顶部
import PageHeader from "./components/PageHeader.vue";
//引入饿了么
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
//引入axios
import * as API from "./http/index";

app.config.globalProperties.$http = API;

app
  .component("PageHeader", PageHeader)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .mount("#app");
