<template>
	<PageHeader state="false" msg="长兴华电科技"></PageHeader>
	<div class="nav_box">
		<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" active-text-color="#f9d855">
			<el-menu-item index="1">修理店</el-menu-item>
			<el-menu-item index="2">品牌电池</el-menu-item>
			<el-menu-item index="3">品牌电动车</el-menu-item>
			<el-menu-item index="4">家政服务</el-menu-item>
		</el-menu>
	</div>


		<div v-if="shopList.length > 0">
			<div  v-for="item in shopList" :key="item">
				<Repair :title="item.shopName" :phone="item.tel" :address="item.address"></Repair>
			</div>
		</div>
		<div v-else>
			<el-empty description="暂无修理店铺信息" :image-size="200"></el-empty>
		</div>

	
</template>

<script>
import Repair from "./repair.vue";
import { bikeShopList } from "../../http/index.js";
export default {
	data() {
		return {
			activeIndex: "1",
			shopList: [],
		};
	},
	methods: {
		handleSelect(key) {
			this.activeIndex = key;
			this.shopList = []
			this.getShopList()
		},
		getShopList() {
			let data = {
				currentPage: 1,
				pagNum: 15,
				longitude: localStorage.getItem("lng"),
				latitude: localStorage.getItem("lat"),
				shopType:this.activeIndex
			};
			bikeShopList(data).then((res) => {
				this.shopList = res.data;
			})
			.catch((error) => {
				console.log(error);
			});
		},
	},
	components: {
		Repair,
	},
	mounted() {
		this.getShopList();
	},
};
</script>

<style>
.el-menu-item {
	width: 25%;
	padding: 0 !important;
	text-align: center;
}
</style>
