import { get, post } from "./http.js";

export const rotationList = (data) => get("rotation/rotationList",data); //首页轮播图
export const chargerList = (data) => post("charger/chargerList", data); //首页充电桩列表
export const wechat = (data) => post("user/weChatLogin", data); //微信登录
export const aliPayLogin = (data) => post("user/aliPayLogin", data); //支付宝登录
export const bikeShopList = (data) => post("bikeShop/bikeShopList", data); //修理店列表
export const chargerInfo = (data) => post("charger/chargerInfo", data); //充电桩详情
export const addHandle = (data) => post("handle/addHandle", data); //申请加盟
export const userCommonlyCharger = (data) =>
  get("user/userCommonlyCharger", data); //常用充电桩
export const userChargerOrder = (data) => post("user/userChargerOrder", data); //充电记录
export const addChargerFault = (data) =>
  post("chargerFault/addChargerFault", data); //故障反馈
export const qrCodeChargerInfo = (data) =>
  post("charger/qrCodeChargerInfo", data); //扫码获取充电桩信息
export const userOrderCounts = (data) => post("user/userOrderCount", data); //统计用户订单数
export const getProtocol = (data) => get("protocol/getProtocol", data); //获取协议
export const advertisement = (data) => get("advertisement/getAdvertisement",data); //首页广告
export const addFeedback = (data) => post("feedback/addFeedback", data); //意见反馈
export const weChatPay = (data) => post("adaPay/weChatPay", data); //微信支付
export const aliPayWap = (data) => post("adaPay/aliPayWap", data); //支付宝支付


export const getShopList = (data) => post("shop/getShopList", data); //商家列表
export const userShopDetail = (data) => post("shop/userShopDetail", data); //商家详情
export const userShopGoodsList = (data) => post("goods/userShopGoodsList", data); //商家分类列表
export const userCategoryGoodsList = (data) => post("goods/userCategoryGoodsList", data); //分类列表商品
export const goodsDetails = (data) => post("goods/goodsDetails", data); //商品详情
export const createShopOrder = (data) => post("shopOrder/createShopOrder", data); //下单
export const shopWeChatPay = (data) => post("adaPay/shopWeChatPay", data); //商品微信支付
export const shopAliPayWap = (data) => post("adaPay/shopAliPayWap", data); //商品支付宝支付
export const goodsSearchList = (data) => post("goods/goodsSearchList", data); //店铺内搜索商品

export const userOrderList = (data) => post("shopOrder/userOrderList", data); //订单列表
export const orderDetail = (data) => post("shopOrder/orderDetail", data); //订单详情
export const orderReverse = (data) => post("adaPay/orderReverse", data); //退款

export const chargerOrderRefund = (data) => post("adaPay/chargerOrderRefund", data); //充电桩退款