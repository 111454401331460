import axios from "axios";
import qs from "qs";
import config from "./config.js";

const instance = axios.create({
  baseURL: config.baseUrl.dev,
  timeout: 60000,
  headers: {
    "content-type": "application/x-www-form-urlencoded",
    token: localStorage.getItem("token"),
  },
});
// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    config.data = qs.stringify(config.data); // 转为formdata数据格式
    // config.headers.token = localStorage.getItem('token')
    return config;
  },
  (error) => Promise.error(error)
);
//get请求
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
//post请求
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

// export default {
// 	get,
// 	post
// }
