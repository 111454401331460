<template>
	<pageHeader state='true' msg='订单确认'></pageHeader>
	<!-- 商品信息 -->
	<div class="goods_box">
		<div class="clearfix">
			<div class="goods_img fl"><img :src="arr['goodsCover']" ></div>
			<div class="fl goods_info">
				<div class="goods_name">{{arr['goodsName']}}</div>
				<div class="goods_price">￥{{arr['price']}}</div>
			</div>
		</div>
	</div>
	<div class="clearfix num_box">
		<div class="fl">购买数量</div>
		<div class="fr">
			<el-input-number size="mini" v-model="num" @change="handleChange" :min="1" :max="100"></el-input-number>
		</div>
	</div>
	<div class="total_box">
		<div class="clearfix total_item">
			<div class="fl">小计：</div>
			<div class="fr">{{total}}元</div>
		</div>
		<div class="clearfix total_item">
			<div class="fl">实付金额：</div>
			<div class="fr red">{{total}}元</div>
		</div>
	</div>
	<div class="btn" @click="save">立即支付</div>
</template>

<script>
	import { createShopOrder,shopWeChatPay,shopAliPayWap } from "../../http/index.js";
	import { ElMessage } from "element-plus";
	export default{
		data(){
			return{
				num:1,
				total:0,
				arr:[],
				payState:1
			}
		},
		methods:{
			handleChange(value) {
				let total = value*this.arr['price']
				this.total = total.toFixed(2)
			},
			readys(callback) {
				// 如果jsbridge已经注入则直接调用
				if (window.AlipayJSBridge) {
					callback && callback();
				} else {
					// 如果没有注入则监听注入的事件
					document.addEventListener("AlipayJSBridgeReady", callback, false);
				}
			},
			jsApiCall() {
				let that = this;
				let wxcon = that.wxcontent;
				// eslint-disable-next-line no-undef
				WeixinJSBridge.invoke("getBrandWCPayRequest", JSON.parse(wxcon), function (res) {
					// eslint-disable-next-line no-undef
					WeixinJSBridge.log(res.err_msg);
					// eslint-disable-next-line no-undef
					WeixinJSBridge.log(res);
					if (res.err_msg == "get_brand_wcpay_request:ok") {
						that.$router.push("orderSuccess");
					}
				});
			},
			
			callpay(val) {
				let jsApiParameters = JSON.parse(val);
				if (jsApiParameters == "undefined") {
					alert("参数错误！");
					return false;
				}
				if (typeof WeixinJSBridge == "undefined") {
					if (document.addEventListener) {
						document.addEventListener( "WeixinJSBridgeReady", this.jsApiCall(), false);
					} else if (document.attachEvent) {
						document.attachEvent("WeixinJSBridgeReady", this.jsApiCall());
						document.attachEvent("onWeixinJSBridgeReady", this.jsApiCall());
					}
				} else {
					this.jsApiCall();
				}
			},
			warnings(mess) {
				ElMessage.warning({
					message: mess,
					type: "warning",
					center: true,
					customClass: "warnClass",
					duration: 1500,
					showClose: true,
				});
			},
			succes(mess) {
				ElMessage.success({
					message: mess,
					type: "success",
					center: true,
					customClass: "warnClass",
					duration: 2000,
					showClose: true,
				});
			},
			save(){
				let that = this
				let data = {
					userId:localStorage.getItem('userId'),
					agentId:this.arr['agentId'],
					shopId:this.arr['shopId'],
					shopName:this.arr['shopName'],
					goodsId:this.arr['goodsId'],
					goodsCount:this.num,
					amount:this.total
				}
				createShopOrder(data).then((res2)=>{
					let datas = {
						userId:localStorage.getItem('userId'),
						orderNo:res2.data.orderNo
					}
					// console.log(datas)
					if (that.payState == 1) {
						shopWeChatPay(datas).then((res) => {
							// console.log(res)
							if (res.code == 400 || res.code == -1) {
								that.warnings(res.message);
							} else if (res.code == 200) {
								that.wxcontent = res.data.expend["pay_info"];
								that.callpay(res.data.expend["pay_info"]);
							}
						})
					    .catch((err) => {
							console.log(err);
					    });
					} else if (that.payState == 2) {
						shopAliPayWap(datas).then((res) => {
							this.readys(function () {window.AlipayJSBridge.call( "tradePay",
								{
									tradeNO: res.data.expend["pay_info"].split('"')[3],
								},
								function (result) {
									if (result["resultCode"] === "9000") {
										that.$router.push({name:'ShopSuccess',params:{
											num:that.num,
											amount:that.total,
											cover:that.arr['goodsCover'],
											name:that.arr['goodsName'],
											price:that.arr['price']
										}})
									} else {
										that.warnings(result["memo"]);
									}
								});
							});
					    })
						.catch((err) => {
							console.log(err);
						});
					}
				})
			}
		},
		mounted() {
			if (/MicroMessenger/.test(window.navigator.userAgent)) {
				this.payState = 1;//微信
			} else if (/AlipayClient/.test(window.navigator.userAgent)) {
				this.payState = 2;//支付宝
			}
			this.arr = this.$route.params
			this.total = this.$route.params.price
		}
	}
</script>

<style scoped lang="scss">
	.goods_box{
		background-color: #fff;
		padding: 1rem;
		width: calc(92% - 2rem);
		margin: 1rem auto;
		border-radius: 1rem;
		font-size: 1.4rem;
		.goods_img{
			width: 8rem;
			height: 6rem;
			img{
				width: 8rem;
				height: 6rem;
			}
		}
		.goods_info{
			width: calc(100% - 9rem);
			margin-left: 1rem;
			line-height: 2rem;
			.goods_name{
				text-overflow: ellipsis;
				display:-webkit-box;
				-webkit-line-clamp:2;
				-webkit-box-orient:vertical;
				overflow: hidden;
			}
			.goods_price{
				color: red;
				text-align: right;
			}
		}
	}
	.num_box{
		width: calc(92% - 2rem);
		margin: 1rem auto;
		background-color: #fff;
		font-size: 1.4rem;
		line-height: 4rem;
		padding: 0 1rem;
	}
	.total_box{
		line-height: 4rem;
		background-color: #fff;
		width: calc(92% - 2rem);
		padding: 0 1rem;
		margin: 1rem auto;
		.total_item{
			border-bottom: 0.0625rem solid #F2f2f2;
		}
		.red{
			color: red;
		}
	}
	.btn{
		background-color: #f9d855;
		color: #fff;
		text-align: center;
		line-height: 4rem;
		font-size: 1.6rem;
		width: 92%;
		margin: 3rem auto;
		border-radius: 1rem;
	}
</style>
