<template>
	<div>
		<button @click="scanCode" v-show="show == true">扫描</button>
	</div>
</template>
<script>
export default {
	data() {
		return {
			show: true,
		};
	},
	methods: {
    // // 点击扫描
    // scanCode () {
    //   wx.scanQRCode({
    //     needResult: 1,
    //     scanType: ['qrCode', 'barCode'],
    //     success: function (res) {
    //       console.log(res)
    //       var result = res.resultStr;
    //     },
    //     error: function (res) {
    //     }
    //   });
    // },
    //  判断
		judge() {
			if (/MicroMessenger/.test(window.navigator.userAgent)) {
				console.log("aaa");
			} else if (/AlipayClient/.test(window.navigator.userAgent)) {
				let that = this;
				that.show = false;
				that.getappliy();
			}
		},
		// 支付宝
		getappliy() {
			let that = this;
			localStorage.removeItem("uniqueCode");
			localStorage.removeItem("chargeId");
			// 如果在vue中调用支付宝扫一扫功能 需要在 AlipayJSBridge 前面加 window 不然会报未定义的错误
			console.log(window.AlipayJSBridge);
			 // 扫描类型  qr 二维码  / bar 条形码
			window.AlipayJSBridge.call("scan",{type: "qr",},
				function (result) {
					var query = result.qrCode.split("?")[1];
					var vars = query.split("&");
					for (var i = 0; i < vars.length; i++) {
						var pair = vars[i].split("=");
						if (pair[0] == "unique") {
							localStorage.setItem("uniqueCode", pair[1]);
							localStorage.setItem("uniqueCodes", pair[1]);
						}
					}
					that.$router.push("chargepile");
				}
			);
		},
	},
	mounted() {
		this.judge();
	},
};
</script>
