<template>
	<PageHeader state="false" msg="长兴华电科技"></PageHeader>
  <!-- <div>{{code}}</div> -->
  <!-- 搜索 -->
	<div class="search clearfix">
    <!-- <div class="search_map fl">
			<div class="img fl">
				<img src="../../assets/icon/map.png" alt="">
			</div>
			<div class="hint_map fl">
				<div>查看</div>
				<div>地图</div>
			</div>
		</div> -->
		<div class="fl search_container">
			<form action="" method="post">
				<input placeholder="请输入充电桩名称/设备号"  v-model="serchText"/>
			</form>
		</div>
		<div @click="search()" class="fl search_btn">搜索</div>
    <!-- <div class="message_box fl">
			<img src="../../assets/icon/lingdang.png" >
		</div> -->
	</div>
	<div class="contains">
		<!-- 轮播图 -->
		<div class="block">
			<el-carousel style="height: 15rem; width: 100%">
				<el-carousel-item v-for="item in imagesBox" :key="item">
					<el-image style="width: 100%; height: 15rem" :fit="'cover'" :src="item.imgsUrl"></el-image>
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 充电桩列表 -->
		<div class="equipment_list" v-if="chargerList.length > 0">
			<ul infinite-scroll-disabled="disabled" class="infinite-list" v-infinite-scroll="getChargerList" style="overflow: auto; height: 100%" infinite-scroll-immediate="false">
				<div class="equipment_item clearfix" v-for="item in chargerList" :key="item" @click="goDetail(item.id)">
					<div class="left_box fl">
						<div class="img_box">
							<img src="../../assets/img/1.jpg" />
						</div>
						<div class="hint_distance">
							距离{{ towNumber(item.distance / 1000) }}km
						</div>
					</div>
					<div class="center_box fl">
						<div class="title">{{ item.addressName }}{{ item.index }}</div>
						<div class="address">{{ item.province }}{{ item.city }}{{ item.area}}{{ item.address }}</div>
						<div class="clearfix">
							<div class="fl hint_info">{{ item.portCount }}插座</div>
							<div class="fl hint_info">{{ browser }}</div>
							<div class="fl">
								<div :class="{ hint_signal_active: item.signalStrength > 4 && item.signalStrength < 16}" class="hint_signal fl " style="height: 1rem;margin-top: 1.925rem;"></div>
								<div :class="{ hint_signal_active: item.signalStrength > 15 && item.signalStrength < 23}" class="hint_signal fl" style="height: 1.5rem;margin-top: 1.425rem;"></div>
								<div :class="{ hint_signal_active: item.signalStrength > 22 && item.signalStrength < 32}" class="hint_signal fl" style="height: 2rem;margin-top: 0.925rem;"></div>
							</div>
						</div>
					</div>
					<div class="right_box fl">
						<div style="border-bottom: 0.0625rem solid #ccc">空闲</div>
						<div v-if="item.isOnline == 1">在线</div>
						<div v-else style="color: red;">离线</div>
					</div>
				</div>
			</ul>
		</div>
		<div class="equipment_list" v-else>
			<el-empty description="没有搜索到充电桩,请更换搜索条件" :image-size="200"></el-empty>
		</div>
	</div>
	<Join></Join>
	
	<div v-show="advState == false" class="adv_box">
		<div class="content" @click="opens()">
			<img :src="imgSrc" />
		</div>
		<div class="quxiao" @click="quxiao()"><img src="../../assets/icon/quxiao2.png" /></div>
	</div>
	<div>
		<el-drawer v-model="drawer" :with-header="false" direction="btt" :z-index='z_index' size="22rem">
			<div class="drawer_box">
				<div class="drawer_item">
					<a href='tel:18657258613'>拨打：18657258613</a>
				</div>
				<div class="drawer_item">
					<a href='tel:0572-6609197'>拨打：0572-6609197</a>
				</div>
				<div class="drawer_item" @click="closes()">取消</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import { useRouter } from "vue-router";
import Join from "../../components/Join.vue";
import { rotationList, chargerList, advertisement} from "../../http/index.js";

export default {
	data() {
		return {
			imagesBox: [], //轮播图数据列表
			chargerList: [], //数据列表
			browser: "",
			count: 1, //数据请求页数
			state: false, //是否继续加载列表 false为继续  true为停止
			imgSrc: "", //广告图地址
			serchText:'',//搜索内容
			advState:false,//广告图状态
			drawer: false,//底部电话抽屉状态
			z_index:10000
		};
	},
	mounted() {
		if (/MicroMessenger/.test(window.navigator.userAgent)) {
			this.browser = "微信";
		} else if (/AlipayClient/.test(window.navigator.userAgent)) {
			this.browser = "支付宝";
		} else {
			this.browser = "其他";
		}
		this.getRotationList();
		this.getChargerList();
		this.getAdvertisements()
		let that = this
		//判断是否扫码进入
		if (localStorage.getItem("uniqueCode") && Number(localStorage.getItem("uniqueCode")) > 0) {
			that.$router.push('chargepile')
		} else{
			localStorage.removeItem('uniqueCode')
		}
	},
	setup() {
		const router = useRouter();
		function goto() {
			router.push("/about");
		}
		return {
			goto,
		};
	},
	components: {
		Join,
	},
	computed: {
		disabled() {
			return this.state;
		},
	},
	methods: {
		towNumber(val) {
			return val.toFixed(2);
		},
		quxiao() {
			this.advState = true;
		},
		opens(){
			this.drawer = true
		},
		closes(){
			this.drawer = false
		},
		//获取轮播图列表
		getRotationList() {
			let data = {
				uniqueCode:localStorage.getItem("uniqueCodes")
			}
			rotationList(data).then((res) => {
				this.imagesBox = res.data.data;
			})
			.catch((error) => {
				console.log(error);
			});
		},
		//获取充电桩列表
		getChargerList() {
			let data = {
				currentPage: this.count,
				pagNum: 10,
				longitude: localStorage.getItem("lng"),
				latitude: localStorage.getItem("lat"),
				flag: 0,
				serchText:this.serchText
			};
			chargerList(data).then((res) => {
				// console.log(res)
				//判断充电桩数量是否加载完
				if (res.data.list.length > 0) {
					this.count = this.count + 1;
					this.chargerList = this.chargerList.concat(res.data.list);
				} else {
					this.state = true;
				}
			})
			.catch((error) => {
				console.log(error);
			});
		},
		//进入到充电桩详情
		goDetail(id) {
			this.$router.push({ name: "ChargePile", params: { id: id } });
		},
		//充电桩搜索
		search(){
			this.count = 1
			this.chargerList = []
			this.getChargerList()
		},
		getAdvertisements() {
			advertisement().then((res) => {
				this.imgSrc = res.data.data[0]["adImgUrl"];
		    })
		    .catch((error) => {
				console.log(error);
		    });
		},
	},
};
</script>

<style scoped lang="scss">
.search {
	background-color: #fff;
	padding: 0.625rem 3%;
}
.search_map .img {
	border-radius: 100%;
	width: 3.6rem;
	height: 3.6rem;
	border: 0.0625rem solid #000;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
	}
}
.search_map .hint_map {
	line-height: 1.8rem;
	letter-spacing: 0.3125rem;
	padding: 0 0.625rem;
}
.search_container {
	width: 65%;
	border: 0.0625rem solid #000;
	height: 3.5rem;
	border-radius: 1rem;
}
.search_container input {
	width: 90%;
	padding-left: 5%;
	height: 3.5rem;
}
.message_box {
	width: 3rem;
	height: 3.5rem;
	margin-left: 1rem;
	img {
		width: 100%;
		height: 100%;
	}
}
.contains {
	width: 94%;
	margin: 1.25rem auto 0;
	padding-bottom: 6.5rem;
}
.block {
	border-radius: 0.625rem;
	overflow: hidden;
	box-shadow: 2px 2px 7px #333;
}
.equipment_list {
	margin-top: 1.875rem;
	background-color: #fff;
	padding: 1.875rem 2%;
	width: 96%;
	border-radius: 1.25rem;
	height: calc(100vh - 35rem);
}
.equipment_item {
	padding: 1.25rem 0;
	border-bottom: 0.125rem solid #000;
}
.left_box {
	width: 27%;
}
.center_box {
	width: 50%;
	padding: 0 2.5%;
}
.right_box {
	width: 16%;
	height: 8.8rem;
	border: 0.0625rem solid #000;
	border-radius: 0.625rem;
	line-height: 4rem;
	text-align: center;
	font-size: 1.125rem;
}
.img_box {
	width: 100%;
	height: 5.7rem;
}
.img_box img {
	width: 100%;
	height: 100%;
}
.hint_distance {
	border: 0.0625rem solid #000;
	border-radius: 0.625rem;
	text-align: center;
	padding: 0.3rem 0;
	margin-top: 0.625rem;
}
.center_box .title {
	font-weight: 700;
	font-size: 1.25rem;
	margin-bottom: 0.3125rem;
}
.center_box .address {
	color: #aaa;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	height: 3.6rem;
}
.hint_info {
	border: 0.0625rem solid #000;
	border-radius: 0.625rem;
	text-align: center;
	padding: 0.3rem 0.5rem;
	margin-top: 0.625rem;
	margin-right: 1.25rem;
}
.hint_signal{
	background-color: #ccc;
	width: 0.5rem;
	
	margin-right: 0.2rem;
}
.hint_signal_active{
	background-color: green;
}


.search_btn{
	background-color: #f9d855;
	color: #fff;
	line-height: 2.5rem;
	padding: 0.1rem 0.9375rem;
	margin-top: 0.5rem;
	border-radius: 0.625rem;
	margin-left: 1rem;
	cursor: pointer;
}

.adv_box {
	position: fixed;
	left: 0;
	top: 4rem;
	width: 100%;
	height: calc(100vh - 4rem);
	z-index: 100000;
	background-color: rgba($color: #000000, $alpha: 0.7);
	.content {
		position: absolute;
		left: 10%;
		top: 15%;
		width: 80%;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.quxiao {
		position: absolute;
		left: 85%;
		top: 13%;
		width: 4rem;
		height: 4rem;
		img {
			width: 100%;
			height: 100%;
		}
	}
}

.adv_box {
	position: fixed;
	left: 0;
	top: 4rem;
	width: 100%;
	height: calc(100vh - 4rem);
	z-index: 1000;
	background-color: rgba($color: #000000, $alpha: 0.7);
	.content {
		position: absolute;
		left: 10%;
		top: 15%;
		width: 80%;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.quxiao {
		position: absolute;
		left: 85%;
		top: 13%;
		width: 4rem;
		height: 4rem;
		img {
			width: 100%;
			height: 100%;
		}
	}
}
.drawer_box{
	padding: 2rem 4% 0;
	.drawer_item{
		line-height: 4.5rem;
		font-size: 1.8rem;
		text-align: center;
		border-bottom: 0.125rem solid #ccc;
	}
}
</style>
