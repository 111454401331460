<template>
	<div id="code">{{code}}</div>
	<!-- <div>{{url}}</div> -->
</template>

<script>
import { wechat, aliPayLogin } from "@/http/index.js";
import AMapLoader from "@amap/amap-jsapi-loader";
import { ElMessage } from "element-plus";
export default {
	data(){
		return {
			code:'登录中......'
		}
	},
	methods: {
		//判断是微信还是支付宝
		estimate() {
			let that = this;
			if (/MicroMessenger/.test(window.navigator.userAgent)) {
				let codes = that.geturl("code");
				// 如果codes有值，那么进行微信登录，否则进行微信授权
				if (codes) {
					let data = {
						code: codes,
					};
					wechat(data).then((res) => {
						if (res.code == 200) {
							localStorage.setItem("userInfo", JSON.stringify(res.data));
							localStorage.setItem("token", res.data["token"]);
							localStorage.setItem("userId", res.data["userId"]);
							that.succes("授权登录成功，跳转中...");
							setTimeout(() => {
								that.$router.push({ path: "/home" });
							}, 1500);
						}
					});
				} else {
					that.wchatauth();
				}
			} else if (/AlipayClient/.test(window.navigator.userAgent)) {
				let codes = that.geturl("auth_code");
				if (codes) {
					let data = {
						auth_code: codes,
					};
					aliPayLogin(data).then((res) => {
						if (res.code == 200) {
							localStorage.setItem("userInfo", JSON.stringify(res.data));
							localStorage.setItem("token", res.data["token"]);
							localStorage.setItem("userId", res.data["userId"]);
							that.succes("授权登录成功，跳转中...");
							setTimeout(() => {
								that.$router.push({ path: "/home" });
							}, 1500);
						}
					});
				} else {
					that.alipayauth();
				}
			} else {
				this.warnings("请使用微信或者支付宝进行扫码");
			}
		},

		//获取用户经纬度，进行缓存
		loadPosition() {
			AMapLoader.load({
				key: "8ddb3b0a2787f46c775d07dbc00dcd7f",
				version: "1.4.16",
				plugins: [],
				AMapUI: {
					version: "1.1",
					plugins: [],
				},
				Loca: {
					version: "1.3.2",
				},
			})
			.then((AMap) => {
				AMap.plugin("AMap.Geolocation", function () {
					const geolocation = new AMap.Geolocation({
						enableHighAccuracy: true,
						timeout: 10000000,
						noIpLocate: 3,
					});
					geolocation.getCurrentPosition(function (status, result) {
						if (status === "complete") {
							localStorage.setItem("lat", result.position.lat);
							localStorage.setItem("lng", result.position.lng);
						} else {
							console.error("高德失败");
						}
					});
				});
			})
			.catch((e) => {
				console.log(e);
			});
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 2000,
				showClose: true,
			});
		},
		// 微信授权
		wchatauth() {
			let appid = "wx9e7965b0594bc863";
			let redirect_url = "https://usertest.cxhdkj.cn?unique="+this.code;
			window.location.href =
				"https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
				appid +
				"&redirect_uri=" +
				redirect_url +
				"&response_type=code&scope=snsapi_userinfo&state=0#wechat_redirect";
		},
		// 支付宝授权
		alipayauth() {
			if(this.code != '登录中......'){
				var redirect_url = encodeURI("https://usertest.cxhdkj.cn?unique="+this.code);
			}
			let appid = "2021002178640442";
			window.location.href = encodeURI(
				"https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=" +
				appid +
				"&redirect_uri=" +
				redirect_url +
				"&scope=auth_user&state=0"
			);
		},
		// 获取url中的指定参数
		geturl (variable) {
			var query = window.location.search.substring(1);
			var vars = query.split('&');
			for (var i = 0; i < vars.length; i++) {
				var pair = vars[i].split('=');
				if (pair[0] == variable) { return pair[1]; }
			}
			return (false);
		},
		//缓存url中的值
		getCode(){
			var query = window.location.href.split('?')[1];
			let that = this
			if(query != undefined){
				if(that.geturl('unique') != false){
					localStorage.setItem("uniqueCode", that.geturl('unique'));
					that.code = localStorage.getItem('uniqueCode')
					that.estimate();
				}else{
					that.estimate();
				}
				
			}else{
				that.estimate();
			}
		}
	},
	mounted() {
		this.loadPosition();
		this.getCode()
	},
};
</script>
