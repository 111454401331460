<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
	<router-link to="/electromobilehome">electromobilehome</router-link> |
    <router-link to="/me">Me</router-link>
	<router-link to="/scanhome">scanhome</router-link>
	
	
  </div>
  <router-view /> -->
	<!-- <router-view></router-view> -->
  
	<!-- <router-view v-slot="{ Component }">
		<keep-alive include="Home,News">
			<component :is="Component" />
		</keep-alive>
	</router-view>
 -->
 <router-view v-slot="{ Component }">
     <keep-alive>
       <component :is="Component"  v-if="$route.meta.keepAlive"  :key="$route.path" />
     </keep-alive>
     <component :is="Component"  v-if="!$route.meta.keepAlive"/>
   </router-view>

  <Tabbar v-if="$route.meta.footShow"></Tabbar>
</template>
<script>
import Tabbar from "./components/NavBar.vue";
export default {
  name: "app",

  components: {
    Tabbar,
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.el-carousel__container {
  height: 15rem !important;
}

// #nav {
//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
