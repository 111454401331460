<template>
	<PageHeader state="false" msg="支付成功"></PageHeader>
	<div class="container_boxs">
		<div class="top clearfix">
			<div class="logo fl"><img :src="arr['cover']" /></div>
			<div class="fl name">
				{{arr['name']}}
			</div>
		</div>
		<div class="info">
			<div class="item clearfix">
				<div class="left fl">数量：</div>
				<div class="right fr">{{ arr['num'] }}</div>
			</div>
			<div class="item clearfix">
				<div class="left fl">支付方式：</div>
				<!-- <div v-show="pay == 1" class="right fr">微信支付</div> -->
				<div class="right fr">支付宝支付</div>
			</div>
			<div class="item clearfix">
				<div class="left fl">优惠：</div>
				<div class="right fr">0元</div>
			</div>
			<div class="item clearfix">
				<div class="left fl">支付金额：</div>
				<div class="right fr">￥{{ arr['amount'] }}</div>
			</div>
		</div>
	</div>
	<div class="hint">
		支付成功...
		<br />
		再逛逛其他商品吧~
	</div>
	<div class="btn_box">
		<div @click="goHome()" class="item fl btn1">首页</div>
		<div @click="goShop()" class="item fl btn2">逛逛优选商城</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			port: "",
			name: "",
			pay: "",
			amount: "",
			startTime: "",
			endTime: "",
			packageName: "",
		};
	},
	mounted() {
		this.arr = this.$route.params
	},
	methods: {
		goHome() {
			this.$router.push("home");
		},
		goShop() {
			this.$router.push("shopHome");
		},
	},
};
</script>

<style lang="scss" scoped>
.top {
	font-size: 1.5rem;
	.logo {
		width: 8rem;
		height: 6rem;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.name {
		width: calc(100% - 9rem);
		// padding: 0 1rem;
		// text-align: center;
		margin-left: 1rem;
		font-weight: 600;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}
.info {
	font-size: 1.4rem;
	padding: 1.25rem 0 1rem;
	.item {
		line-height: 2.5rem;
		.left {
			font-weight: 600;
		}
		.right {
		}
	}
}
.hint {
	margin-top: 5rem;
	text-align: center;
	font-weight: 700;
	font-size: 1.8rem;
	line-height: 5rem;
}
.btn_box {
	position: fixed;
	bottom: 3rem;
	width: 94%;
	left: 3%;
	text-align: center;
	.item {
		background-color: #f9d855;
		color: #fff;
		border-radius: 0.625rem;
		line-height: 4rem;
	}
	.btn1 {
		width: 20%;
		margin-right: 5%;
		background-color: #426aa4;
	}
	.btn2 {
		width: 75%;
	}
}
</style>

