<template>
  <!-- <PageHeader state="true" msg="充电桩"></PageHeader> -->
	<div class="page_header">
		<div @click="goBack()" class="icon">
			<i class="el-icon-arrow-left"></i>
		</div>
		<div class="content">充电桩</div>
	</div>
	<div style="height: 4rem;width: 100%;"></div>
	<div class="container_box">
		<div class="top clearfix">
			<div class="logo fl"><img src="../../assets/img/1.jpg" /></div>
			<div class="name_box fl">
				<div class="name">{{ name }}</div>
				<div>充电桩</div>
			</div>
		</div>
		<div class="centre">
			<div class="hint">请使用空闲的插座通道进行充电</div>
			<div class="port_list clearfix">
				<div v-for="item in info.portList" :key="item" class="item fl" :class="{ selected: item.index == currentSort, active: item.status == 2,}" @click="selected(item.index, item.status)">
					<div class="left fl">
						<div>
							<div>{{ item.index }}号</div>
							<div>充电桩</div>
						</div>
					</div>
					<div v-if="item.status == 1" class="right fl">空<br />闲</div>
					<div v-else-if="item.status == 2" class="right fl">占<br />用</div>
					<div v-else-if="item.status == 3" class="right fl">维<br />护</div>
					<div v-else-if="item.status == 4" class="right fl">禁<br />用</div>
				</div>
			</div>
		</div>
		<div class="bottom">
			<div class="hint">
				已选择：<span>{{ currentSort }}号</span> 充电桩
			</div>
			<div class="save" @click="save()">确认</div>
		</div>
	</div>
  
	<div v-show="advState == false" class="adv_box">
		<div class="content" @click="opens()">
			<img :src="imgSrc" />
		</div>
		<div class="quxiao" @click="quxiao()"><img src="../../assets/icon/quxiao2.png" /></div>
	</div>
  
	<!-- <div>
		<el-drawer v-model="drawer" :with-header="false" direction="btt" :z-index="z_index" size="22rem">
			<div class="drawer_box">
				<div class="drawer_item">
					<a href='tel:18657258613'>拨打：18657258613</a>
				</div>
				<div class="drawer_item">
					<a href='tel:0572-6609197'>拨打：0572-6609197</a>
				</div>
				<div class="drawer_item" @click="closes()">取消</div>
			</div>
		</el-drawer>
	</div> -->
</template>

<script>
import { chargerInfo, qrCodeChargerInfo,advertisement } from "../../http/index.js";
import { ElMessage } from "element-plus";
export default {
	data() {
		return {
			currentSort: 0,
			info: [],//
			name: "",//
			advState:true,//广告图状态
			drawer: false,//底部电话抽屉状态
			z_index:10000,
			imgSrc:''
		};
	},
	mounted() {
		let ids = 0;
		if (this.$route.params.id) {
			ids = this.$route.params.id;
			localStorage.setItem("chargeId", ids);
			this.getChargerInfo(ids);
		} else if (localStorage.getItem("uniqueCode")) {
			ids = localStorage.getItem("uniqueCode");
			this.getChargerInfo2(ids);
			this.getAdvertisements(ids)
		} else {
			this.getChargerInfo(localStorage.getItem("chargeId"));
		}
		
		
	},
	methods: {
		getAdvertisements(uniqueCode) {
			let data = {
				uniqueCode:uniqueCode
			}
			advertisement(data).then((res) => {
				this.advState = false
				this.imgSrc = res.data.data[0]["adImgUrl"];
				this.adJumpUrl = res.data.data[0]['adJumpUrl']
			})
			.catch((error) => {
				console.log(error);
			});
		},
		quxiao() {
			this.advState = true;
		},
		opens(){
			// this.drawer = true
			location.href=this.adJumpUrl
		},
		closes(){
			this.drawer = false
		},
		goBack() {
			localStorage.removeItem('uniqueCode')
			this.$router.go(-1);
		},
		getChargerInfo(id) {
			let data = { chargerId: id,};
			chargerInfo(data).then((res) => {
				if(res.code == -1){
					this.warnings(res.message)
				}else{
					this.info = res.data;
					this.name = res.data.charger["chargerIndex"];
					localStorage.setItem("taocan",JSON.stringify(res.data.chargerChargings));
				}
			})
			.catch((error) => {
				console.log(error);
			});
		},
		getChargerInfo2(id) {
			let data = { uniqueCode: id,};
			qrCodeChargerInfo(data).then((res) => {
				if(res.code == -1){
					this.warnings(res.message)
				}else{
					this.info = res.data;
					this.name = res.data.charger["chargerIndex"];
					localStorage.setItem( "taocan",JSON.stringify(res.data.chargerChargings));
					localStorage.setItem("chargeId", res.data.charger.id);
				}
			})
			.catch((error) => {
				console.log(error);
			});
		},
		selected(index, state) {
			if (state == 1) {
				this.currentSort = index;
			}
		},
		save() {
			if (this.currentSort == 0) {
				this.warnings("请选择充电端口");
				return false;
			}
			this.$router.push({
				name: "OrderAffirm",
				params: { id: this.currentSort, name: this.name },
			});
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 2000,
				showClose: true,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.container_box {
  width: 88%;
  margin: 1.25rem auto;
  background-color: #fff;
  box-shadow: 3px 6px 6px #aaa;
  border-radius: 1.25rem;
  padding: 0 2%;
  .top {
    border-bottom: 0.0625rem solid;
    padding: 1.8rem 0;
    .logo {
      width: 10rem;
      height: 5.5rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name_box {
      width: 10rem;
      padding: 0 1rem;
      text-align: center;
      font-weight: 600;
      font-size: 1.5rem;
      .name {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 1rem;
      }
    }
  }
  .centre {
    .hint {
      color: #aaa;
      line-height: 3rem;
	  font-size: 1.5rem;
    }
    .port_list {
      padding: 0 0 1.875rem;
      .item {
        margin-left: 10%;
        width: 35%;
        box-shadow: 3px 6px 6px #aaa;
        border-radius: 1.2rem;
        overflow: hidden;
        margin-bottom: 1.5rem;
        text-align: center;
		font-size: 1.3rem;
		letter-spacing: 0.125rem;
        .left {
          width: 50%;
          background-color: #f0f0f0;
          padding: 1rem 10%;
          line-height: 2rem;
          color: #000 !important;
        }
        .right {
          width: 30%;
          line-height: 2rem;
          padding: 1rem 0;
        }
      }
    }
  }

  .bottom {
    padding: 0rem 0 3.125rem 0;
    text-align: right;
    .hint {
      margin-right: 6%;
      margin-bottom: 0.625rem;
      span {
        font-weight: 600;
        font-size: 1.5rem;
      }
    }
    .save {
      width: 90%;
      margin: 0 auto;
      text-align: center;
      line-height: 5rem;
      background-color: #f9d855;
      border-radius: 1.25rem;
      color: #fff;
      font-size: 1.75rem;
    }
  }
}

.active {
  background-color: red !important;
  color: #fff !important;
}
.selected {
  background-color: green !important;
  color: #fff;
}
.adv_box {
  position: fixed;
  left: 0;
  top: 4rem;
  width: 100%;
  height: calc(100vh - 4rem);
  z-index: 1000;
  background-color: rgba($color: #000000, $alpha: 0.7);
  .content {
    position: absolute;
    left: 10%;
    top: 15%;
    width: 80%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .quxiao {
    position: absolute;
    left: 85%;
    top: 13%;
    width: 4rem;
    height: 4rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.drawer_box{
	padding: 2rem 4% 0;
	.drawer_item{
		line-height: 4.5rem;
		font-size: 1.8rem;
		text-align: center;
		border-bottom: 0.125rem solid #ccc;
	}
}
</style>
