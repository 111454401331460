<template>
  <div class="bodys">
    <PageHeader state="false" msg="扫码"></PageHeader>
    <div class="icon_img">
      <img src="../../assets/icon/saoma.png" />
    </div>
    <div class="change_box">
      <div @click="chongdianzhuang()" class="item fl">充电桩</div>
      <div @click="chongdiangui()" class="item fl">充电柜</div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
export default {
  methods: {
    chongdianzhuang() {
      let that = this;
      if (/MicroMessenger/.test(window.navigator.userAgent)) {
        window.location.href = "https://usertest.cxhdkj.cn/static/code.html";
      } else if (/AlipayClient/.test(window.navigator.userAgent)) {
        that.$router.push({
          path: "/aliscan",
        });
      } else {
        this.warnings("请在微信或者支付宝使用该功能");
      }
    },
    chongdiangui() {
      this.warnings("暂未开放，敬请期待");
    },
    warnings(mess) {
      ElMessage.warning({
        message: mess,
        type: "warning",
        center: true,
        customClass: "warnClass",
        duration: 1500,
        showClose: true,
      });
    },
    succes(mess) {
      ElMessage.success({
        message: mess,
        type: "success",
        center: true,
        customClass: "warnClass",
        duration: 2000,
        showClose: true,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.bodys {
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.5);
  .icon_img {
    width: 15rem;
    margin: 35% auto 0;
    img {
      width: 100%;
    }
  }
  .change_box {
    background-color: #fff;
    width: 100%;
    height: 15rem;
    position: fixed;
    bottom: 0;
    left: 0;
    margin-bottom: 5.9rem;
    .item {
      background-color: #f9d855;
      width: 30%;
      margin-left: 13.33%;
      line-height: 3.125rem;
      text-align: center;
      margin-top: 5.9375rem;
    }
  }
}
</style>
