<template>
  <PageHeader state="false" msg="长兴华电科技"></PageHeader>
  <!-- 个人信息展示 -->
  <div class="info_box">
    <div class="info_top clearfix">
      <div class="avatar_img fl">
        <img src="../../assets/img/1.jpg" />
      </div>
      <div class="info_sim fl">
        <div class="name">{{ name }}</div>
        <!-- <div class="phone">153****1585</div> -->
      </div>
    </div>
    <div class="info_bottom">
      <el-row>
        <el-col :span="8">
          <div @click="a()" style="border-right: 0.0625rem solid #000">
            <div>充电券</div>
            <div class="num">{{ counts["couponCount"] }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div
            @click="goRechargeRecord()"
            style="border-right: 0.0625rem solid #000"
          >
            <div>充电记录</div>
            <div class="num">{{ counts["chargerOrderCount"] }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div @click="goOrder()">
            <div>优选订单</div>
            <div class="num">{{ counts["shopOrderCount"] }}</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>

  <!-- 菜单栏 -->
  <div class="nav_box">
    <el-row>
      <el-col :span="8">
        <div class="nav_item" @click="goOften()">
          <div>
            <img src="../../assets/icon/chongdianzhuang.png" />
          </div>
          <div>常用充电桩</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="nav_item" @click="goFeedback()">
          <div>
            <img src="../../assets/icon/fankui.png" />
          </div>
          <div>意见反馈</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="nav_item" @click="goService()">
          <div>
            <img src="../../assets/icon/kefu.png" />
          </div>
          <div>联系客服</div>
        </div>
      </el-col>
    </el-row>
  </div>

  <!-- other -->
  <div class="other_box">
    <!-- <div style="border-bottom: 0.0625rem solid" class="clearfix">
			<div class="fl">充电提醒</div>
			<div class="fr">
				<el-switch v-model="value" active-color="#13ce66" inactive-color="#ff4949"/>
			</div>
		</div> -->
    <div @click="goto()">关于我们</div>
  </div>
</template>
<script type="text/javascript">
import { useRouter } from "vue-router";
import { userOrderCounts } from "../../http/index.js";
import { ElMessage } from "element-plus";
export default {
	data() {
		return {
			value: true,
			counts: [],
			name: "",
		};
	},
	methods: {
		getCount() {
			let data = {
				userId: JSON.parse(localStorage.getItem("userInfo"))["userId"],
			};
			userOrderCounts(data).then((res) => {
				this.counts = res.data;
			})
			.catch((error) => {
				console.log(error);
			});
		},
		a() {
			this.warnings("暂未开放，敬请期待");
		},
		goOrder(){
			this.$router.push('shopRecord')
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 2000,
				showClose: true,
			});
		},
	},
	setup() {
		const router = useRouter();
		const goto = () => {
			router.push("/about");
		};
		const goRechargeRecord = () => {
			router.push("/recharge_record");
		};
		const goFeedback = () => {
			router.push("/feedback");
		};
		const goService = () => {
			router.push("/service");
		};
		const goOften = () => {
			router.push("/often");
		};
		return {
			goto,
			goRechargeRecord,
			goFeedback,
			goService,
			goOften,
		};
	},
	mounted() {
		this.getCount();
		this.name = JSON.parse(localStorage.getItem("userInfo"))["userName"];
    // var mobile = JSON.parse(localStorage.getItem('userInfo'))['mobile']
    // this.mobile = mobile.substr(0,3) + "****" + mobile.substr(7)
	},
};
</script>

<style type="text/css" scoped="scoped" lang="scss">
.info_box {
  width: 100%;
  height: 20rem;
  background-color: #fff;
  border-bottom-left-radius: 1.875rem;
  border-bottom-right-radius: 1.875rem;
  box-shadow: 0px 6px 6px #ccc;
  .info_top {
    width: 70%;
    padding: 2rem 15%;
    .avatar_img {
      width: 7rem;
      height: 7rem;
      border-radius: 100%;
      border: 0.0625rem solid #000;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info_sim {
      width: 60%;
      text-align: center;
      margin-left: 10%;
      .name {
        font-weight: 700;
        font-size: 2rem;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 0.625rem;
      }
      .phone {
        color: #aaa;
        font-size: 1.75rem;
      }
    }
  }
  .info_bottom {
    text-align: center;
    font-size: 1.5rem;
    .num {
      font-size: 1.75rem;
      font-weight: 600;
    }
  }
}
.nav_box {
  width: 92%;
  margin-left: 4%;
  background-color: #fff;
  margin-top: 2.1875rem;
  height: 8rem;
  border-radius: 2rem;
  text-align: center;
  .nav_item {
    padding: 1rem 0;
  }
  img {
    width: 4rem;
    height: 4rem;
  }
}
.other_box {
  background-color: #fff;
  width: 82%;
  padding: 0 5%;
  margin-left: 4%;
  margin-top: 1.25rem;
  border-radius: 0.625rem;
  line-height: 4rem;
}
</style>
