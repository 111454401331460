<template>
	<pageHeader state='true' msg='商品详情'></pageHeader>
	<div>
		<div>
			<div class="shop_info">
				<div class="banner"><img :src="info.goodsCover" ></div>
				<div class="info_box">
					<div class="goods_name">{{info.goodsTitle}}</div>
				<!-- 	<div class="goods_desc">
						这个是商品圣诞节哈三百多斤哈四大皆空哈师大静安寺客式就卡死很快就撒决这个是商品圣诞节哈三百多斤哈四大皆空哈师大静安寺客户端巴萨快捷方式就卡死哈师大静安寺客户端巴萨快捷方式就卡死很快就撒决
					</div> -->
					<div class="clearfix">
						<div class="fl sale">销量：{{info.totalSales}}</div>
						<div class="fr original">￥{{info.originPrice}}</div>
						<div class="fr price">￥{{info.sellPrice}}</div>
					</div>
				</div>
			</div>
			<div class="shop_detail">
				<img :src="info.goodsDetails" >
			</div>
		</div>
	</div>
	<div class="btn" @click="goPay">去购买</div>
</template>

<script>
	import { goodsDetails } from "../../http/index.js";
	export default{
		data(){
			return {
				info:[],
				shopInfo:[]
			}
		},
		methods:{
			goPay(){
				this.$router.push({name:'ShopPay',params:{goodsCover:this.info['goodsCover'],agentId:this.shopInfo['userId'],shopId:this.shopInfo['id'],shopName:this.shopInfo['shopName'],goodsId:this.info['id'],goodsName:this.info['goodsTitle'],price:this.info['sellPrice']}})
			},
			getInfo(){
				let data = {
					goodsId:this.goodsId
				}
				goodsDetails(data).then((res)=>{
					if(res.code == 200){
						this.info = res.data.goodsInfo
						this.shopInfo = res.data.shopInfo
					}
				})
			}
		},
		mounted() {
			this.goodsId = this.$route.params.id
			this.getInfo()
		},
		activated() {
			if(!this.$route.meta.isBack && this.isFirstEnter){
				this.info = []
				this.shopInfo = []
				this.goodsId = this.$route.params.id
				this.getInfo()
			}
			this.$route.meta.isBack=false
			this.isFirstEnter=true;
		},
		created() {
			this.isFirstEnter=false;
		}
	}
</script>

<style scoped lang="scss">
	.shop_info{
		.banner{
			width: 100%;
			height: 25rem;
			img{
				width: 100%;
				height: 25rem;
			}
		}
		.info_box{
			background-color: #fff;
			padding: 1rem;
			font-size: 1.4rem;
			margin: 1rem 0 1.5rem;
			.goods_name{
				line-height: 2.5rem;
				font-size: 1.6rem;
				font-weight: 600;
				// text-overflow: ellipsis;
				// display:-webkit-box;
				// -webkit-line-clamp:2;
				// -webkit-box-orient:vertical;
				// overflow: hidden;
				margin-bottom: 0.5rem;
			}
			.goods_desc{
				color: #aaa;
				margin-bottom: 1rem;
			}
			.sale{
				color: #aaa;
			}
			.original{
				color: #999;
				text-decoration: line-through;
				
			}
			.price{
				color: red;
				font-size: 1.6rem;
				font-weight: 600;
				margin-right: 0.5rem;
			}
		}
	}
	.shop_detail{
		width: 100%;
		margin-top: -5px;
		padding-bottom: 8rem;
		img{
			width: 100%;
		}
	}
	.btn{
		width: 100%;
		height: 5rem;
		position: fixed;
		bottom: 0;
		background-color: #f9d855;
		color: #fff;
		text-align: center;
		line-height: 5rem;
		font-size: 1.6rem;
	}
</style>
