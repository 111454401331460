<template>
	<PageHeader state="true" msg="关于我们"></PageHeader>
	<div class="logo">
		<img src="../../assets/img/1.jpg" />
	</div>
	<div class="info_box">
		<div class="clearfix" style="border-bottom: 0.0625rem solid">
			<div class="fl title">当前版本</div>
			<div class="fr">v1.0</div>
		</div>
		<div class="clearfix">
			<div class="fl title">客服电话</div>
			<div class="fr">0572-6609197</div>
		</div>
	</div>
</template>
<script type="text/javascript">
import PageHeader from "../../components/PageHeader.vue";
export default {
	methods: {
		goBack() {
			this.$router.go(-1);
		},
	},
	components: {
		PageHeader,
	},
};
</script>
<style type="text/css" scoped="scoped" lang="scss">
.logo {
	width: 12.5rem;
	height: 12.5rem;
	margin: 4rem auto;
	img {
		width: 100%;
		height: 100%;
	}
}
.info_box {
	background-color: #fff;
	padding: 0 4%;
	line-height: 4.5rem;
	font-size: 1.5rem;
	.title {
		font-weight: 600;
	}
}
</style>
