<template>
	<PageHeader state="true" msg="常用充电桩记录"></PageHeader>
	<!-- 充电桩列表 -->
	<div class="equipment_list" v-if="chargerList.length > 0">
		<div class="equipment_item clearfix" v-for="item in chargerList" :key="item" @click="goDetail(item.id)">
			<div class="left_box fl">
				<div class="img_box"><img src="../../assets/img/1.jpg" /></div>
        <!-- <div class="hint_distance">距离{{towNumber(item.distance/1000)}}km</div> -->
			</div>
			<div class="center_box fl">
				<div class="title">{{ item.address_name }}{{ item.charger_index }}</div>
				<div class="address">
					{{ item.province }}{{ item.city }}{{ item.area }}{{ item.address }}
				</div>
				<div class="clearfix">
					<div class="fl hint_info">{{ item.portCount }}插座</div>
					<div class="fl hint_info">{{ browser }}</div>
				</div>
			</div>
			<div class="right_box fl">
				<div style="border-bottom: 0.0625rem solid #ccc">空闲</div>
				<div>在线</div>
			</div>
		</div>
	</div>
	<div v-else>
		<el-empty description="暂无常用充电桩记录" :image-size="200"></el-empty>
	</div>
  
</template>

<script>
import PageHeader from "../../components/PageHeader.vue";
import { userCommonlyCharger } from "../../http/index.js";
export default {
	data() {
		return {
			chargerList: [],
			browser: "",
		};
	},
	methods: {
		getUserCommonlyCharger() {
			if (/MicroMessenger/.test(window.navigator.userAgent)) {
				this.browser = "微信";
			} else if (/AlipayClient/.test(window.navigator.userAgent)) {
				this.browser = "支付宝";
			} else {
				this.browser = "其他";
			}
			let data = {
				userId: JSON.parse(localStorage.getItem("userInfo"))["userId"],
			};
			userCommonlyCharger(data).then((res) => {
				this.chargerList = res.data.data;
			})
			.catch((error) => {
				console.log(error);
			});
		},
		goDetail(id) {
			this.$router.push({ name: "ChargePile", params: { id: id } });
		},
	},
	mounted() {
		this.getUserCommonlyCharger();
	},
	components: {
		PageHeader,
	},
};
</script>

<style>
.equipment_list {
	margin-top: 1.875rem;
	background-color: #fff;
	padding: 1.875rem 2%;
	width: 96%;
	border-radius: 1.25rem;
}
.equipment_item {
	padding: 1.25rem 0;
	border-bottom: 0.125rem solid #000;
}
.left_box {
	width: 27%;
}
.center_box {
	width: 50%;
	padding: 0 2.5%;
}
.right_box {
	width: 16%;
	height: 8.8rem;
	border: 0.0625rem solid #000;
	border-radius: 0.625rem;
	line-height: 4rem;
	text-align: center;
	font-size: 1.125rem;
}
.img_box {
	width: 100%;
	height: 5.7rem;
}
.img_box img {
	width: 100%;
	height: 100%;
}
.hint_distance {
	border: 0.0625rem solid #000;
	border-radius: 0.625rem;
	text-align: center;
	padding: 0.3rem 0;
	margin-top: 0.625rem;
}
.center_box .title {
	font-weight: 700;
	font-size: 1.25rem;
	margin-bottom: 0.3125rem;
}
.center_box .address {
	color: #aaa;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	height: 3.6rem;
}
.hint_info {
	border: 0.0625rem solid #000;
	border-radius: 0.625rem;
	text-align: center;
	padding: 0.3rem 0.5rem;
	margin-top: 0.625rem;
	margin-right: 1.25rem;
}
</style>
