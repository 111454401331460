<template>
	<div class="container_boxs clearfix">
		<div class="fl">
			<div>{{ title }}</div>
			<div>联系方式：{{ phone }}</div>
			<div>地址：{{ address }}</div>
		</div>
		<div class="fr btn">
			<a :href="'tel:' + phone">拨打<br />电话</a>
		</div>
	</div>
</template>

<script>
export default {
	name: "Repair",
	props: {
		title: String,
		phone: String,
		address: String,
	},
	methods: {},
};
</script>

<style scoped>
.btn {
	border: 0.0625rem solid;
	line-height: 2.6rem;
	border-radius: 0.625rem;
	padding: 0 1.25rem;
}
</style>
