<template>
	<PageHeader state="false" msg="华电优选商城"></PageHeader>
	<el-empty v-show="shopList.length == 0" description="暂未开放,敬请期待" :image-size="200"></el-empty>
	<div v-show="shopList.length != 0" class="shop_box">
		<form action="" @submit.prevent="getShopLists">
			<div class="search_box">
				<input type="search" placeholder="请输入店铺名" v-model="searchValue"/>
			</div>
		</form>
		
		<div class="list">
			<ul infinite-scroll-disabled="disabled" class="infinite-list" v-infinite-scroll="getShopLists" style="overflow: auto; height: 100%" infinite-scroll-immediate="false">
				<div class="shop_item" @click="goDetail(item.id)" v-for="item in shopList" :key="item">
					<div class="clearfix">
						<div class="shop_item_img fl">
							<el-image style="width: 8rem;height: 6rem;" :src="item.shopLogoImg" fit="cover" ></el-image>
							<!-- <img :src="item.shopLogoImg" > -->
						</div>
						<div class="fl shop_item_info">
							<div class="shop_item_name">{{item.shopName}}</div>
							<div class="shop_item_address">地址：{{item.address}}</div>
						</div>
					</div>
					<div class="clearfix">
						<div class="shop_item_distance">距离：{{ towNumber(item.distance / 1000) }}km</div>
					</div>
				</div>
			</ul>	
		</div>
	</div>
</template>

<script>
	import { getShopList } from "../../http/index.js";
	export default{
		data(){
			return {
				shopList:[],
				state: false, //是否继续加载列表 false为继续  true为停止
				searchValue:'',//搜索内容
				searchValue2:'',//搜索内容2
				currentPage:1,//页数
			}
		},
		methods:{
			getShopLists(){
				if(this.searchValue != '' && this.searchValue2 == ''){
					this.currentPage = 1
					this.searchValue2 = this.searchValue
				}else if(this.searchValue != this.searchValue2){
					this.currentPage = 1
					this.searchValue2 = this.searchValue
				}
				let data = {
					currentPage:this.currentPage,
					pagNum:10,
					longitude:localStorage.getItem('lng'),
					latitude:localStorage.getItem('lat'),
					searchValue:this.searchValue
				}
				getShopList(data).then((res)=>{
					if(res.code == 200 && res.data != ''){
						this.shopList = this.shopList.concat(res.data)
						this.currentPage = this.currentPage+1
					}else if(res.code == 200 && res.data == ''){
						this.state = true
					}
				})
			},
			goDetail(id){
				this.$router.push({name:'ShopDetail',params:{id:id}})
			},
			towNumber(val) {
				return val.toFixed(2);
			},
		},
		computed: {
			disabled() {
				return this.state;
			},
		},
		mounted() {
			this.getShopLists()
		}
	}
</script>

<style scoped lang="scss">
	.shop_box{
		height: calc(90vh - 4rem);
		// background-color: #fff;
		.search_box{
			width: 92%;
			margin: 1rem auto;
			background-color: #fff;
			height: 3rem;
			font-size: 1.4rem;
			input{
				padding: 0 5%;
				width: 90%;
				height: 3rem;
			}
		}
		.list{
			width: 92%;
			margin: 0 auto;
			height: calc(100vh - 17rem);
			// background-color: red;
			background-color: #fff;
			border-radius: 0.6rem;
			.shop_item{
				width: calc(92% - 2rem);
				padding: 1rem;
				margin: 0.5rem auto 1.5rem;
				// height: 4rem;
				box-shadow: 0.125rem 0.125rem 0.375rem #aaa;
				.shop_item_img{
					width: 8rem;
					height: 6rem;
					img{
						width: 8rem;
						height: 6rem;
					}
				}
				.shop_item_info{
					margin-left: 1rem;
					width: calc(100% - 9rem);
					font-size: 1.4rem;
					line-height: 2rem;
					.shop_item_name{
						font-weight: 600;
						font-size: 1.6rem;
					}
					.shop_item_address{
						color: #666;
						text-overflow: ellipsis;
						display:-webkit-box;
						-webkit-line-clamp:2;
						-webkit-box-orient:vertical;
						overflow: hidden;
						
					}
				}
				.shop_item_distance{
					line-height: 2.5rem;
					font-size: 1.2rem;
					color: #aaa;
				}
			}
		}
	}
</style>
