<template>
	<PageHeader state="true" msg="意见反馈"></PageHeader>
	<div>
		<div class="content_box">
      <!-- <textarea @input="suggestRemnant()" rows="" cols="" placeholder="请输入故障内容" maxlength="50"></textarea>
			<div class="hint">/50</div> -->
			<el-input type="textarea" placeholder="请输入故障内容" v-model="textarea" maxlength="50" show-word-limit rows="4"></el-input>
		</div>

    <!-- <div class="img_box">
			<input type="file" name="" id="files" value="" style="display: none;" />
			<label for="files">
				<div class="change_btn">
					<img src="../../assets/icon/add.png" >
				</div>
			</label>
			
		</div> -->
	</div>
	<div class="btn" @click="save()">提交</div>
</template>

<script>
import { ref } from "vue";
import { addFeedback } from "../../http/index.js";
import { ElMessage } from "element-plus";
export default {
	methods: {
		save() {
			if (this.textarea.length == 0) {
				this.warnings("内容不能为空");
				return false;
			}
			let data = {
				content: this.textarea,
				userId: JSON.parse(localStorage.getItem("userInfo"))["userId"],
			};
			addFeedback(data).then(() => {
				this.succes("反馈成功，我们将尽快处理");
				let that = this;
				setTimeout(function () {
					that.$router.go(-1);
				}, 1500);
			})
			.catch((error) => {
				console.log(error);
			});
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
	},
	setup() {
		return {
			textarea: ref(""),
		};
	},
};
</script>

<style lang="scss" scoped>
input:focus,
textarea:focus {
	outline: none;
}
.content_box {
	width: 82%;
	margin: 1.5rem auto 0;
	padding: 1.25rem 4%;
	height: 10rem;
	background-color: #fff;
	box-shadow: 3px 4px 6px #aaa;
	border-radius: 1.2rem;
}
.img_box {
	width: 90%;
	margin: 1.875rem auto 0;
	.change_btn {
		border: 0.0625rem dashed #8a8a8a;
		width: 5rem;
		height: 5rem;
		padding: 0.625rem;
		img {
			width: 100%;
		}
	}
}
.btn {
	width: 90%;
	position: fixed;
	left: 5%;
	background-color: #f9d855;
	height: 5rem;
	color: #fff;
	bottom: 5%;
	text-align: center;
	border-radius: 0.9375rem;
	line-height: 5rem;
	font-size: 1.5rem;
}
</style>
