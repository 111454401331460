<template>
  <div class="join_box clearfix">
    <div @click="open()" class="txt fl">加<br />盟<br />+<br />+</div>
    <div @click="goto()" class="img_box fl">
      <img src="../assets/img/2.jpg" />
    </div>
    <div @click="close()" class="quxiao">
      <img src="../assets/icon/quxiao2.png" />
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  name: "Join",
  methods: {
    open() {
      var a = document.getElementsByClassName("join_box")[0];
      a.style.cssText = "right:1% !important";
    },
    close() {
      var a = document.getElementsByClassName("join_box")[0];
      a.style.cssText = "right:-47% !important";
    },
  },
  setup() {
    const router = useRouter();
    const goto = () => {
      router.push("/joinin");
    };
    return {
      goto,
    };
  },
};
</script>

<style scoped lang="scss">
.join_box {
  border: 0.0625rem solid #000000;
  border-radius: 0.625rem;
  position: fixed;
  right: -47%;
  font-size: 1.3rem;
  width: 50%;
  bottom: 20%;
  background-color: #fff;
  padding: 0.625rem 1.25rem 0.625rem 0;
  transition: all 0.5s;
  .txt {
    width: 15%;
    text-align: center;
    height: 9.5rem;
  }
  .img_box {
    width: 85%;
    height: 9.5rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .quxiao {
    width: 3.125rem;
    height: 3.125rem;
    position: absolute;
    right: -1rem;
    top: -1.5rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
