<template>
	<PageHeader state="true" msg="订单确认"></PageHeader>
	<div class="container_box">
		<div class="top clearfix">
			<div class="logo fl"><img src="../../assets/img/1.jpg" /></div>
			<div class="name_box fl">
				<div class="name">{{ name }}</div>
					<div>充电桩</div>
			</div>
			<div class="fr" style="text-align: center">
				<div style="margin-bottom: 1rem">{{ port }}号</div>
				<div>充电桩</div>
			</div>
		</div>
		<div class="package">
			<div>
				<div class="title">套餐：</div>
				<div class="change_list">
					<div class="package_item" v-for="(item, index) in packages" :key="item">
						<input v-model="type" class="fl" type="radio" name="" :id="item.id" :value="index"/>
						<label :for="item.id">
							<div class="clearfix">
								<div class="info fl">
									<div>{{ item.packageName }}</div>
									<div style="color: #ccc">{{ item.minutes }}分钟</div>
								</div>
								<div class="fr price">￥{{ item.amount }}</div>
							</div>
						</label>
					</div>
				</div>
			</div>
		</div>

		<div class="package">
			<div>
				<div class="title">支付方式：</div>
				<div class="change_list" style="line-height: 3.8rem">
					<div v-show="pay == 1" class="package_item">
						<input v-model="pay" class="fl" type="radio" name="" id="pay1" value="1"/>
						<label for="pay1">
							<div class="clearfix">
								<div class="info fl">
									<div>微信</div>
								</div>
								<div class="fr icons">
									<img src="../../assets/icon/weixin.png" />
								</div>
							</div>
						</label>
					</div>

					<div v-show="pay == 2" class="package_item">
						<input v-model="pay" class="fl" type="radio" name="" id="pay2" value="2"/>
						<label for="pay2">
							<div class="clearfix">
								<div class="info fl">
									<div>支付宝</div>
								</div>
								<div class="fr icons">
									<img src="../../assets/icon/zhifubao.png" />
								</div>
							</div>
						</label>
					</div>
				</div>
			</div>
		</div>
		<div class="btn" @click="save()">立即充电</div>
	</div>
</template>

<script>
import { ElMessage } from "element-plus";
import { weChatPay, aliPayWap } from "../../http/index.js";
export default {
	data() {
		return {
			type: 0, //套餐下标
			pay: 1, //支付方式 1微信 2支付宝
			packages: [], //充电桩套餐数组
			port: "", //充电端口号
			name: "", //充电桩名称
			wxcontent: "",
		};
	},
	mounted() {
		this.port = this.$route.params.id; //充电端口号
		this.name = this.$route.params.name;
		this.packages = JSON.parse(localStorage.getItem("taocan"));
		if (/MicroMessenger/.test(window.navigator.userAgent)) {
			this.pay = 1;
		} else if (/AlipayClient/.test(window.navigator.userAgent)) {
			this.pay = 2;
		}
  },
	methods: {
		readys(callback) {
			// 如果jsbridge已经注入则直接调用
			if (window.AlipayJSBridge) {
				callback && callback();
			} else {
			// 如果没有注入则监听注入的事件
				document.addEventListener("AlipayJSBridgeReady", callback, false);
			}
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 2000,
				showClose: true,
			});
		},
		save() {
			var that = this;
			var data = {
				userId: JSON.parse(localStorage.getItem("userInfo"))["userId"],
				chargerId: that.packages[that.type]["chargerId"],
				chargingId: that.packages[that.type]["id"],
				goodsTitle: that.packages[that.type]["packageName"],
				goodsDesc: that.packages[that.type]["packageName"],
				goodsName: that.packages[that.type]["packageName"],
				quantity: 1,
				payAmt:that.packages[that.type]['amount']+'.00',
				port: that.port, //端口号
			};
			if (that.pay == 1) {
				weChatPay(data).then((res) => {
					if (res.code == 400 || res.code == -1) {
						that.warnings(res.message);
					} else if (res.code == 200) {
						that.wxcontent = res.data.expend["pay_info"];
						that.callpay(res.data.expend["pay_info"]);
					}
				})
				.catch((err) => {
					console.log(err);
				});
			} else if (that.pay == 2) {
				aliPayWap(data).then((res) => {
					this.readys(function () {
						window.AlipayJSBridge.call("tradePay",
							{
								tradeNO: res.data.expend["pay_info"].split('"')[3],
							},
							function (result) {
								if (result["resultCode"] === "9000") {
									that.$router.push({
										name: "OrderSuccess",
										params: {
											name: that.name, //充电桩名称
											port: that.port, //充电桩名称
											pay: that.pay, //支付方式
											amount: that.packages[that.type]["amount"] + ".00", //支付金额
											packageName: that.packages[that.type]["packageName"], //套餐名称
											startTime: new Date().toLocaleString(), //开始时间
											endTime: new Date( new Date().getTime() + that.packages[that.type]["minutes"] * 60000 ).toLocaleString(), //结束时间
										},
									});
								} else {
									that.warnings(result["memo"]);
								}
							}
						);
					});
				})
				.catch((err) => {
					console.log(err);
				});
			}
		},
		jsApiCall() {
			let that = this;
			let wxcon = that.wxcontent;
			// eslint-disable-next-line no-undef
			WeixinJSBridge.invoke(
				"getBrandWCPayRequest",
				JSON.parse(wxcon),
				function (res) {
					// eslint-disable-next-line no-undef
					WeixinJSBridge.log(res.err_msg);
					// eslint-disable-next-line no-undef
					WeixinJSBridge.log(res);
					if (res.err_msg == "get_brand_wcpay_request:ok") {
						that.$router.push("orderSuccess");
					}
				}
			);
		},

		callpay(val) {
			let jsApiParameters = JSON.parse(val);
			if (jsApiParameters == "undefined") {
				alert("参数错误！");
				return false;
			}
			if (typeof WeixinJSBridge == "undefined") {
				if (document.addEventListener) {
					document.addEventListener(
					"WeixinJSBridgeReady",
					this.jsApiCall(),
					false
				)} else if (document.attachEvent) {
					document.attachEvent("WeixinJSBridgeReady", this.jsApiCall());
					document.attachEvent("onWeixinJSBridgeReady", this.jsApiCall());
				}
			} else {
				this.jsApiCall();
			}
		},
	},
};
</script>

<style scoped lang="scss">
.container_box {
	width: 92%;
	margin: 1.25rem auto;
	font-size: 1.5rem;
}
.top {
	background-color: #fff;
	box-shadow: 3px 6px 6px #aaa;
	border-radius: 1.25rem;
	padding: 1.5rem 3%;

	.logo {
		width: 10rem;
		height: 5.5rem;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.name_box {
		width: 8rem;
		padding: 0 1rem;
		text-align: center;
		font-weight: 600;

		.name {
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			margin-bottom: 1rem;
		}
	}
}
.package {
	background-color: #fff;
	box-shadow: 3px 6px 6px #aaa;
	border-radius: 1.25rem;
	padding: 1.5rem 3% 0;
	margin-top: 1.5rem;
	.title {
		font-weight: 600;
	}
	.package_item {
		margin-top: 0.875rem;
		border-bottom: 0.0625rem solid;
		padding-bottom: 0.625rem;
		input {
			margin-top: 1rem;
			width: 1.8rem;
			height: 1.8rem;
		}
		.info {
			margin-left: 1.2rem;
			text-align: center;
		}
		.price {
			line-height: 3.8rem;
			font-size: 1.75rem;
			font-weight: 600;
		}
		.icons {
			width: 3.125rem;
			height: 3.125rem;
			margin-top: 0.375rem;
			img {
				width: 3.125rem;
				height: 3.125rem;
			}
		}
	}
}
.package_item:nth-last-child(1) {
	border: none;
}
.btn {
	background-color: #f9d855;
	line-height: 4.5rem;
	text-align: center;
	color: #fff;
	font-size: 1.6rem;
	border-radius: 0.625rem;
	position: fixed;
	width: 92%;
	bottom: 2rem;
}
</style>
